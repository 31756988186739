import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getImage } from 'gatsby-plugin-image';
import uuid from 'react-uuid';
import { navigate } from 'gatsby-link';
import { Link } from 'gatsby';
import { connect, useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import getSlug from '../../utils/getSlug';
import { setSearchFullscreen } from '../../state/searchSlice';
import ModeSwitcher from '../common/menuAndFooter/ModeSwitcher';
import ContrastSwitcher from '../common/menuAndFooter/ContrastSwitcher';
import WcagFontSizeSwitcher from '../common/menuAndFooter/WcagFontSizeSwitcher';
import Bip from '../common/menuAndFooter/Bip';
import SingleSocialMediaItem from '../common/menuAndFooter/SingleSocialMediaItem';
import Magnifier from '../../assets/magnifier.svg';
import EuFlag from '../common/menuAndFooter/EuFlag';
import getPreparedSlug from '../../utils/getPreparedSlug';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import LanguageSelectorNew from '../languageSelector/LanguageSelectorNew';
import Popup from '../common/popup/Popup';

const StyledFooterWrapper = styled.div`
	align-items: flex-start;
	background-color: ${({ theme }) => theme.colors.darkBlue};
	display: none;
	flex-direction: column;
	height: calc(100vh + 120px);
	justify-content: flex-start;
	overflow-y: scroll;
	position: relative;
	width: 100vw;
	::-webkit-scrollbar {
		background: transparent;
		width: 0;
	}
	&.expanded {
		background-color: ${({ theme }) => theme.colors.darkBlue};
		height: auto;
		overflow: visible;
	}
	@media (min-width: 1024px) {
		display: flex;
	}
`;

const StyledFooterLeftPartContainer = styled.div`
	height: ${({ innerHeight }) => `calc(${innerHeight}px * 0.84)`};
	padding: 0 0 20px 74px;
	width: 80vw;
	@media (min-height: 700px) {
		height: ${({ innerHeight }) => `calc(${innerHeight}px * 0.79)`};
		padding: 0 0 42px 74px;
		@media (min-width: 1025px) and (max-width: 1224px) {
			padding: 0 0 42px 40px;
		}
	}
	@media (min-height: 937px) {
		height: ${({ innerHeight }) => `calc(${innerHeight}px * 0.79)`};
		padding: 0 0 42px 74px;
	}
	@media (min-height: 1050px) {
		height: ${({ innerHeight }) => `calc(${innerHeight}px * 0.75)`};
	}
	&.expanded {
		height: auto;
	}
`;

const StyledFooterLeftParkWrapper = styled.div`
	border: 1px solid rgba(255, 255, 255, 0.3);
	border-right: none;
	height: calc(100% + 120px);
	padding: 20px 20px 10px 40px;
	@media (min-height: 700px) {
		padding: 40px 60px 30px 70px;
		@media (min-width: 1025px) and (max-width: 1224px) {
			padding: 40px 12px 30px 29px;
		}
	}
`;

const StyledFooterRightPartContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.green};
	bottom: 0;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-start;
	padding: 16vh 74px 20px 0;
	position: absolute;
	right: 0;
	width: 20vw;
	z-index: 5;
	@media (min-height: 700px) {
		padding: 21vh 74px 42px 0;
		@media (min-width: 1025px) and (max-width: 1224px) {
			padding: 21vh 40px 42px 0;
		}
	}
	@media (min-height: 1050px) {
		padding: 25vh 74px 42px 0;
	}
	&.expanded {
		height: 100%;
	}
`;

const StyledFooterRightPartWrapper = styled.div`
	align-items: flex-end;
	border: 1px solid ${({ theme }) => theme.colors.darkBlue};
	border-left: none;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-start;
	padding-left: 40px;
	width: 100%;
	@media (min-width: 1025px) and (max-width: 1366px) {
		padding-left: 23px;
	}
`;

const StyledToolsWithHeader = styled.div`
	margin-bottom: 60px;
	text-align: left;
	width: 100%;
`;

const StyledSubtitle = styled.div`
	color: ${({ theme }) => theme.colors.darkBlue};
	font-size: 1rem;
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	line-height: 1.625rem;
	margin-bottom: 20px;
`;

const StyledTopWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	height: 16vh;
	justify-content: center;
	width: 100%;
	@media (min-height: 700px) {
		height: 21vh;
	}
	@media (min-height: 1050px) {
		height: 25vh;
	}
`;

const StyledLogoWrapper = styled.div`
	padding-bottom: 0;
	@media (min-height: 937px) {
		padding-bottom: 4vh;
	}
	@media (min-height: 1050px) {
		padding-bottom: 7vh;
	}
`;

const LogoBigWrapper = styled.img`
	width: 200px;
	@media (min-height: 937px) {
		width: 283px;
	}
`;

const StyledMainCategoriesWrapper = styled.div`
	align-items: flex-end;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding-bottom: 4vh;
	width: 100%;
	@media (min-height: 800px) {
		padding-bottom: 8vh;
	}
`;

const StyledMainCategoriesTitle = styled.div`
	color: ${({ theme }) => theme.colors.white};
	cursor: pointer;
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 0.9375rem;
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	line-height: 1.25rem;
	text-decoration: none;
	width: calc((28% - 120px) / 2);
	@media (min-width: 1440px) {
		width: calc((28% - 160px) / 2);
	}
	&:hover {
		color: ${({ theme }) => theme.colors.green};
	}
	&:nth-child(1),
	&:nth-child(2),
	&:nth-child(3) {
		cursor: default;
		width: 24%;
	}
	&:nth-child(4) {
		width: 120px;
		@media (min-width: 1440px) {
			width: 160px;
		}
	}
	&:nth-child(5) {
		text-align: left;
	}
	&:last-child {
		text-align: left;
	}
	&:nth-child(5) {
		@media (min-width: 1025px) and (max-width: 1660px) {
			padding-left: 15px;
		}
	}
	&:nth-child(4) {
		@media (min-width: 1025px) and (max-width: 1660px) {
			margin-left: -20px;
		}
	}
	&:nth-last-child(-n + 3) {
		@media (min-width: 1025px) and (max-width: 1660px) {
			width: 87px;
		}
	}
`;

const StyledColumnsWrapper = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
`;

const StyledNestedPagesColumnWrapper = styled.div`
	//width: 22%;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-start;
	padding-right: 20px;
	width: 24%;
`;

const StyledFooterItem = styled.div`
	position: relative;
	width: 100%;
	* {
		&:hover {
			color: ${({ theme }) => theme.colors.green};
		}
	}
	&.first-level {
		font-size: 0.875rem;
		font-weight: ${({ theme }) => theme.fontWeight.regular};
		line-height: 1rem;
		margin-bottom: 16px;
		a {
			font-weight: ${({ theme }) => theme.fontWeight.regular};
		}
		@media (min-height: 700px) {
			font-size: 0.9375rem;
			line-height: 1.25rem;
		}
		@media (min-height: 800px) {
			margin-bottom: 16px;
			font-size: 1.0625rem;
			line-height: 1.375rem;
		}
		@media (min-height: 937px) {
			margin-bottom: 24px;
		}
	}
	&.active {
		padding-left: 20px;
		.section-header {
			color: ${({ theme }) => theme.colors.green};
		}
	}
	&.second-level {
		font-size: 0.75rem;
		font-weight: ${({ theme }) => theme.fontWeight.medium};
		line-height: 1rem;
		margin: 5px 0;
		padding-left: 20px;
	}
	&.third-level {
		a {
			font-size: 0.75rem;
			font-weight: ${({ theme }) => theme.fontWeight.regular};
			line-height: 1rem;
			margin: 5px 0;
			padding-left: 20px;
		}
	}
`;

const StyledPlusSign = styled.div`
	height: 20px;
	left: -14px;
	position: absolute;
	top: 0;
	transform: scale(0.8);
	transition: transform 0.3s ease-in-out;
	width: 20px;
	&.expanded {
		left: -12px;
		div {
			&:before {
				transform: scale(0) rotate(0);
				transition: transform 0.3s ease-in-out;
			}
		}
	}
`;

const StyledOtherFooterElementsWrapper = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	padding-bottom: 50px;
	width: 28%;
`;

const StyledRowWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;

	&.first-row {
		align-items: center;
		flex-wrap: wrap;
		height: 34px;
		@media (max-height: 700px) and (min-width: 1025px) {
			height: 74px;
		}
	}
`;

const StyledLink = styled(Link)`
	color: ${({ theme }) => theme.colors.white};
	cursor: pointer;
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	text-decoration: none;
`;

const StyledNotExpandedItem = styled.div`
	color: ${({ theme }) => theme.colors.white};
	cursor: pointer;
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	text-decoration: none;
`;

const StyledMagnifierContainer = styled.div`
	align-items: center;
	border-bottom: 1px solid ${({ theme }) => theme.colors.darkBlue};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 40px;
	padding: 0 16px 8px 20px;
	width: 100%;
	@media (min-height: 700px) {
		margin-bottom: 100px;
	}
	div {
		color: ${({ theme }) => theme.colors.darkBlue};
		font-size: 1rem;
		font-weight: ${({ theme }) => theme.fontWeight.medium};
	}
`;

const StyledSocialMediaWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	height: 100%;
	justify-content: flex-end;
`;

const StyledIconsWrapper = styled.div``;

const StyledButtonWrapper = styled.div`
	align-items: flex-end;
	border: 1px solid ${({ theme }) => theme.colors.darkBlue};
	border-right: none;
	border-top: none;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	height: 60px;
	justify-content: space-between;
	margin-bottom: 50px;
	text-align: center;
	width: 100%;
	@media (min-height: 700px) {
		margin-bottom: 70px;
	}
	&.expanded {
			// background-color: ${({ theme }) => theme.colors.white};
		div {
			&:before {
				transform: scale(0) rotate(0);
				transition: transform 0.3s ease-in-out;
			}
		}
	}
`;

const StyledButtonLeftHalf = styled.div`
	color: ${({ theme }) => theme.colors.darkBlue};
	font-size: 0.9375rem;
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	padding-bottom: 10px;
	width: 185px;
	@media (min-width: 1440px) {
		font-size: 1rem;
	}
`;

const StyledButtonRightHalf = styled.div`
	border-left: 1px solid ${({ theme }) => theme.colors.darkBlue};
	height: 100%;
	position: relative;
	width: 50px;
	&:after {
		background-color: ${({ theme }) => theme.colors.darkBlue};
		bottom: 17px;
		content: '';
		height: 4px;
		left: calc(50% - 9px);
		position: absolute;
		width: 18px;
	}
	&:before {
		background-color: ${({ theme }) => theme.colors.darkBlue};
		bottom: 10px;
		content: '';
		height: 18px;
		left: calc(50% - 2px);
		position: absolute;
		transform: scale(100%) rotate(180deg);
		transition: transform 0.3s ease-in-out;
		width: 4px;
	}

	&.columns {
		border: none;

		&:before {
			background-color: ${({ theme }) => theme.colors.green};
			bottom: unset;
			height: 10px;
			left: 4px;
			top: 6px;
			width: 2px;
		}
		&:after {
			background-color: ${({ theme }) => theme.colors.green};
			bottom: unset;
			height: 2px;
			left: 0;
			top: 10px;
			width: 10px;
		}
		&.expanded {
			&:before {
				opacity: 0;
			}
			&:after {
				width: 25px;
			}
		}
	}
`;

const StyledAddressInformationsWrapper = styled.div`
	border-top: 1px solid white;
	color: ${({ theme }) => theme.colors.white};
	margin-top: 20px;
	padding-top: 20px;
	@media (min-height: 700px) {
		margin-top: 80px;
	}
	@media (min-height: 937px) {
		margin-top: 100px;
	}
	p:first-of-type {
		font-size: 0.9375rem;
		@media (min-width: 1650px) {
			font-size: 1rem;
		}
	}
	p {
		font-size: 0.8125rem;
		line-height: 1.25rem;
		@media (min-width: 1650px) {
			font-size: 0.875rem;
			line-height: 1.6875rem;
		}
	}
`;

const FooterDesktopNew = ({ footerData, globals, activeSite, otherPagesPathsForLangChange, preparedSitemapSimplify }) => {
	const footerNodes = footerData?.nodes || [];
	const modalText = globals?.nodes?.[0]?.modalText || '';
	const btnClose = globals?.nodes?.[0]?.btnClose || '';
	const btnLinkTo = globals?.nodes?.[0]?.btnLinkTo || '';
	const globalData = globals?.nodes?.[0] || [];
	const showSitemap = globalData?.showSitemapText || '';
	const hideSitemap = globalData?.hideSitemapText || '';
	const dispatch = useDispatch();
	const pageTheme = useSelector((state) => state.pageTheme.pageTheme);
	const searchFullscreen = useSelector((state) => state.searchFullscreen.searchFullscreen);
	const searchInPortText = globalData?.searchInPortText || '';
	const availabilityToolsText = globalData?.availabilityToolsText || '';
	const followUsText = globalData?.followUsText || '';
	const logoBig = globalData?.logoBig?.url || '';
	const bipIcon = globalData?.bipIcon?.url || '';
	const bipHref = globalData?.bipUrl || '';
	const socialMediaData = globalData?.socialMedia || [];
	const contrastOnIcon = globalData?.contrastOnIcon?.url || '';
	const contrastOffIcon = globalData?.contrastOffIcon?.url || '';
	const contrastDarkIcon = globalData?.contrastDarkIcon?.url || '';
	const wcagAIcon = globalData?.wcagAIcon?.url || '';
	const wcagAIconDarkMode = globalData?.wcagAIconDarkMode?.url || '';
	const wcagAPlusIcon = globalData?.wcagAPlusIcon?.url || '';
	const wcagAPlusIconDarkMode = globalData?.wcagAPlusIconDarkMode?.url || '';
	const ueFlagIcon = globalData?.ueFlagIcon?.url || '';
	const ueFlagUrlId = globalData?.ueFlagUrl?.id || '';
	const addressInformations = globalData?.footerAddressInformations || '';
	const backgroundImageData = globalData?.footerBackgroundImage || '';
	const [showFooterExpanded, setShowFooterExpanded] = useState(false);
	const [activeItems, setActiveItems] = useState([]);
	const [linkTo, setLinkTo] = useState('');
	const [isOpenModal, setIsOpenModal] = useState(false);
	const isDarkMode = pageTheme === 'darkMode';
	const preparedSlug = getPreparedSlug(ueFlagUrlId, preparedSitemapSimplify);
	const necessaryCookies = Cookies.get('necessaryCookies');
	const [heightDimension, setHeightDimension] = useState(0);

	const handleResize = () => {
		setHeightDimension(window.innerHeight);
	};
	useEffect(() => {
		setHeightDimension(window.innerHeight);
		window.addEventListener('resize', handleResize, false);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const mainCategories = [];
	footerNodes.forEach((item) => {
		const mainCategoryTitle = item?.menuItemTitle || '';
		const mainCategorySlug = item?.linkToPage?.slug || '';
		const mainCategoryExternalLink = item?.linkToExternalPage || '';
		const mainCategoryShowModal = item?.showModal || false;

		mainCategories.push({
			title: mainCategoryTitle,
			slug: mainCategorySlug,
			linkToExternalPage: mainCategoryExternalLink,
			showModal: mainCategoryShowModal,
		});
	});
	const nodesWithColumnData = footerNodes.filter((item) => item.treeChildren.length > 0);
	const orderFooterListByPosition = (item) => {
		item?.treeChildren?.sort((a, b) => (a.position > b.position ? 1 : -1));
	};

	const onMainCategoryItemClick = (menuItem) => {
		const isAnyOfNestedPages =
			menuItem.title.includes('Porty') ||
			menuItem.title.includes('Ports') ||
			menuItem.title.includes('Häfen') ||
			menuItem.title.includes('Biznes') ||
			menuItem.title.includes('Business') ||
			menuItem.title.includes('Unser Angebot') ||
			menuItem.title.includes('Spółka') ||
			menuItem.title.includes('Company') ||
			menuItem.title.includes('Gesellschaft');
		if (!isAnyOfNestedPages) {
			if (menuItem?.slug) {
				navigate(`/${getSlug(menuItem.slug, activeSite)}`);
			}
		}
	};
	const togglePopup = () => {
		setIsOpenModal(!isOpenModal);
	};
	const showModal = (item) => {
		setIsOpenModal(true);
		setLinkTo(item.linkToExternalPage);
	};

	const nodesWithPrefixedPaths = [];
	nodesWithColumnData.forEach((item) => {
		const mainCategorySlug = (() => {
			switch (item.menuItemTitle) {
				case 'Porty':
					return 'porty';
				case 'Ports':
					return 'ports';
				case 'Häfen':
					return 'hafen';
				case 'Biznes':
					return 'biznes';
				case 'Business':
					return 'business';
				case 'Unser Angebot':
					return 'unser-angebot';
				case 'Spółka':
					return 'spolka';
				case 'Company':
					return 'company';
				case 'Gesellschaft':
					return 'gesellschaft';
				default:
					return '';
			}
		})();
		const slug = item?.linkToPage?.slug || '';
		const concatSlug = slug ? `${mainCategorySlug}/${slug}` : `${mainCategorySlug}`;
		nodesWithPrefixedPaths.push({
			mainSlug: concatSlug,
			...item,
		});
	});

	const searchFullscreenHandler = () => {
		dispatch(setSearchFullscreen(!searchFullscreen));
	};

	const handleItemClick = (e, hasChildren, isShowModal, externalLink, id, slug, isExistingPage) => {
		const clickedItem = e.target;
		const isSectionHeaderItem = clickedItem.classList.contains('section-header');
		let newArray = [...activeItems];
		if (hasChildren) {
			if (newArray.includes(id)) {
				setShowFooterExpanded(false);
				if (isExistingPage) {
					if (isSectionHeaderItem) {
						navigate(`/${getSlug(slug, activeSite)}`);
					}
				}
				const tempArray = newArray.filter((item) => item !== id);
				setActiveItems(tempArray);
			} else {
				newArray = [...newArray, id];
				setActiveItems(newArray);
			}
		} else if (externalLink) {
			if (isShowModal) {
				setIsOpenModal(true);
				setLinkTo(externalLink);
			} else {
				window.open(externalLink, '_blank').focus();
			}
		} else {
			navigate(`/${getSlug(slug, activeSite)}`);
		}
	};

	const handleShowSitemap = () => {
		if (showFooterExpanded) {
			setShowFooterExpanded(false);
			setActiveItems([]);
		} else {
			setShowFooterExpanded(true);
		}
	};
	const mainCategoriesItem = (item) => {
		let linkOrDiv = '';

		if (item.linkToExternalPage) {
			if (item.showModal) {
				linkOrDiv = <div onClick={() => showModal(item)}>{item.title}</div>;
			} else {
				linkOrDiv = <a href={item.linkToExternalPage}>{item.title}</a>;
			}
		} else linkOrDiv = <div onClick={() => onMainCategoryItemClick(item)}>{item.title}</div>;
		return <>{linkOrDiv}</>;
	};

	const SecondThirdLevelItem = (item, compoundSlug) => {
		let linkOrDiv = '';
		const isLinkToPage = item?.linkToPage || '';
		const isExisitingPage = isLinkToPage?.isExistingPage || '';

		if (item?.linkToExternalPage) {
			if (item?.showModal) {
				linkOrDiv = <div onClick={() => showModal(item)}>{item.menuItemTitle}</div>;
			} else {
				linkOrDiv = <a href={item.linkToExternalPage}>{item.menuItemTitle}</a>;
			}
		} else if (isLinkToPage) {
			if (isExisitingPage) {
				linkOrDiv = <Link to={`/${getSlug(compoundSlug, activeSite)}`}>{item.menuItemTitle}</Link>;
			} else linkOrDiv = <div>{item.menuItemTitle}</div>;
		} else linkOrDiv = <div>{item.menuItemTitle}</div>;
		return <>{linkOrDiv}</>;
	};

	return (
		<>
			{isOpenModal && (
				<Popup
					visible={isOpenModal}
					href={linkTo}
					close={() => togglePopup()}
					modalText={modalText}
					btnLinkTo={btnLinkTo}
					btnClose={btnClose}
				/>
			)}
			<StyledFooterWrapper className={showFooterExpanded || Object.keys(activeItems).length !== 0 ? 'expanded' : ''}>
				<StyledTopWrapper>
					<StyledLogoWrapper>
						<LogoBigWrapper src={logoBig} />
					</StyledLogoWrapper>
				</StyledTopWrapper>
				<StyledFooterLeftPartContainer
					innerHeight={heightDimension}
					className={showFooterExpanded || Object.keys(activeItems).length !== 0 ? 'expanded' : ''}
				>
					<StyledFooterLeftParkWrapper>
						{/* <StyledBottomWrapper className={showFooterExpanded || Object.keys(activeItems).length !== 0 ? 'expanded' : ''}> */}
						<StyledMainCategoriesWrapper>
							{mainCategories.map((item) => (
								<StyledMainCategoriesTitle key={item.originalId}>{mainCategoriesItem(item)}</StyledMainCategoriesTitle>
							))}
						</StyledMainCategoriesWrapper>
						<StyledColumnsWrapper>
							{nodesWithPrefixedPaths.map((item, keyId) => {
								const firstLevelChildren = item?.treeChildren || [];
								const mainSlug = item?.mainSlug || '';
								orderFooterListByPosition(item);
								const prefixKey = keyId;

								return (
									<StyledNestedPagesColumnWrapper key={uuid()}>
										{firstLevelChildren.map((firstLevelItem, key) => {
											const itemTitle = firstLevelItem?.menuItemTitle || '';
											const secondLevelChildren = firstLevelItem?.treeChildren || [];
											const slugFirstLevel = firstLevelItem?.linkToPage?.slug || '';
											const compoundSlugFirstLevel = slugFirstLevel ? `${mainSlug}/${slugFirstLevel}` : `${mainSlug}`;
											orderFooterListByPosition(firstLevelItem);
											const hasChildren = firstLevelItem?.treeChildren.length > 0;
											const itemKey = `${prefixKey}-${key}`;
											const isShowModal = firstLevelItem?.showModal || false;
											const externalLink = firstLevelItem?.linkToExternalPage || '';
											const isExistingPage = firstLevelItem?.linkToPage?.isExistingPage || '';

											return (
												<StyledFooterItem
													key={uuid()}
													onClick={(e) => {
														handleItemClick(
															e,
															hasChildren,
															isShowModal,
															externalLink,
															itemKey,
															compoundSlugFirstLevel,
															isExistingPage,
														);
													}}
													className={
														activeItems.includes(itemKey) || showFooterExpanded
															? 'first-level active'
															: 'first-level'
													}
												>
													{hasChildren && (
														<StyledPlusSign
															className={
																activeItems.includes(itemKey) || showFooterExpanded ? 'expanded' : ''
															}
														>
															<StyledButtonRightHalf
																className={
																	activeItems.includes(itemKey) || showFooterExpanded
																		? 'expanded columns'
																		: 'columns'
																}
															/>
														</StyledPlusSign>
													)}
													<StyledNotExpandedItem className="section-header">{itemTitle}</StyledNotExpandedItem>
													{(activeItems.includes(itemKey) || showFooterExpanded) &&
														secondLevelChildren &&
														secondLevelChildren.map((secondLevelItem) => {
															const itemTitleSecondLevel = secondLevelItem?.menuItemTitle || '';
															const thirdLevelChildren = secondLevelItem?.treeChildren;
															const slugSecondLevel = secondLevelItem?.linkToPage?.slug || '';
															const compoundSlugSecondLevel = thirdLevelChildren
																? `${compoundSlugFirstLevel}/${slugSecondLevel}`
																: `${compoundSlugFirstLevel}`;
															orderFooterListByPosition(secondLevelItem);
															return (
																<StyledFooterItem key={uuid()} className="second-level">
																	<StyledLink>
																		{SecondThirdLevelItem(
																			secondLevelItem,
																			compoundSlugSecondLevel,
																			activeSite,
																		)}
																	</StyledLink>
																	{thirdLevelChildren &&
																		thirdLevelChildren.map((thirdLevelItem) => {
																			const itemTitleThirdLevel = thirdLevelItem?.menuItemTitle || '';
																			const slugThirdLevel = thirdLevelItem?.linkToPage?.slug || '';
																			const compoundSlugThirdLevel = slugThirdLevel
																				? `${compoundSlugSecondLevel}/${slugThirdLevel}`
																				: `${compoundSlugSecondLevel}`;
																			return (
																				<StyledFooterItem key={uuid()} className="third-level">
																					<StyledLink>
																						{SecondThirdLevelItem(
																							thirdLevelItem,
																							compoundSlugThirdLevel,
																							activeSite,
																						)}
																					</StyledLink>
																				</StyledFooterItem>
																			);
																		})}
																</StyledFooterItem>
															);
														})}
												</StyledFooterItem>
											);
										})}
									</StyledNestedPagesColumnWrapper>
								);
							})}
							<StyledOtherFooterElementsWrapper>
								<StyledIconsWrapper>
									<StyledRowWrapper className="first-row">
										<Bip bipHref={bipHref} bipIcon={bipIcon} />
										<EuFlag preparedSlug={preparedSlug} activeSite={activeSite} ueFlagIcon={ueFlagIcon} />
										<ModeSwitcher globals={globalData} />
										<LanguageSelectorNew
											otherPagesPathsForLangChange={otherPagesPathsForLangChange}
											necessaryCookies={necessaryCookies}
										/>
									</StyledRowWrapper>
								</StyledIconsWrapper>
								<StyledAddressInformationsWrapper dangerouslySetInnerHTML={getHtmlFromRedactorField(addressInformations)} />
							</StyledOtherFooterElementsWrapper>
						</StyledColumnsWrapper>
						{/* </StyledBottomWrapper> */}
					</StyledFooterLeftParkWrapper>
				</StyledFooterLeftPartContainer>
				<StyledFooterRightPartContainer className={showFooterExpanded || Object.keys(activeItems).length !== 0 ? 'expanded' : ''}>
					<StyledFooterRightPartWrapper>
						<StyledRowWrapper>
							<StyledButtonWrapper className={showFooterExpanded ? 'expanded' : ''} onClick={() => handleShowSitemap()}>
								<StyledButtonLeftHalf>{showFooterExpanded ? hideSitemap : showSitemap}</StyledButtonLeftHalf>
								<StyledButtonRightHalf />
							</StyledButtonWrapper>
						</StyledRowWrapper>
						<StyledRowWrapper>
							<StyledMagnifierContainer onClick={() => searchFullscreenHandler()}>
								<div>{searchInPortText}</div>
								<img alt="magnifier" src={Magnifier} />
							</StyledMagnifierContainer>
						</StyledRowWrapper>
						<StyledToolsWithHeader>
							<StyledSubtitle>{availabilityToolsText}</StyledSubtitle>
							<StyledRowWrapper>
								<WcagFontSizeSwitcher
									isDarkMode={isDarkMode}
									wcagAIcon={wcagAIcon}
									wcagAIconDarkMode={wcagAIconDarkMode}
									wcagAPlusIcon={wcagAPlusIcon}
									wcagAPlusIconDarkMode={wcagAPlusIconDarkMode}
									isFooter
								/>
								<ContrastSwitcher
									pageTheme={pageTheme}
									contrastOnIcon={contrastOnIcon}
									contrastOffIcon={contrastOffIcon}
									contrastDarkIcon={contrastDarkIcon}
									isFooter
								/>
							</StyledRowWrapper>
						</StyledToolsWithHeader>
						<StyledToolsWithHeader>
							<StyledSubtitle>{followUsText}</StyledSubtitle>
							<StyledRowWrapper>
								<StyledSocialMediaWrapper>
									{socialMediaData.map((item) => {
										const socialMediaUrl = item?.socialMediaLink || '';
										const socialMediaIcon = item?.icon?.url || '';
										const socialMediaDarkIcon = item?.iconDark?.url || '';
										return (
											<SingleSocialMediaItem
												key={socialMediaUrl}
												socialMediaUrl={socialMediaUrl}
												socialMediaIcon={socialMediaIcon}
												socialMediaDarkIcon={socialMediaDarkIcon}
												isFooter
											/>
										);
									})}
								</StyledSocialMediaWrapper>
							</StyledRowWrapper>
						</StyledToolsWithHeader>
					</StyledFooterRightPartWrapper>
				</StyledFooterRightPartContainer>
			</StyledFooterWrapper>
		</>
	);
};

export default connect((state) => ({
	pageTheme: state.pageTheme.pageTheme,
}))(FooterDesktopNew);
