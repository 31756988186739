import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { connect } from 'react-redux';
import ArrowIcon from '../../assets/inline/arrow.inline.svg';
import { setMenuFullscreen } from '../../state/menuFullscreenSlice';
import getSlug from '../../utils/getSlug';
import Popup from '../common/popup/Popup';

const StyledMenuWrapper = styled.ul`
	//height: calc(var(--inner-height) - 90px);
	background-color: var(--menu-color);
	color: ${({ theme }) => theme.colors.white};
	// height: calc(100% - 90px);
	height: 100%;
	left: 0;
	overflow-y: auto;
	padding: 70px 0 90px 0;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 105;
	display: none;
	// transform: translateX(0%) translateY(-100%);
	// transition: transform 0.7s cubic-bezier(0.77, 0, 0.175, 1);
	//::-webkit-scrollbar {
	//	display: none;
	//}
	&.active {
		//transform: translateX(0%) translateY(0%);
		display: block;
		// 	&::before {
		// 		transform: translateX(0%) translateY(0%);
		// 	}
	}

	ul {
		li {
			display: flex;
			flex-direction: column;

			a {
				text-decoration: none;
			}
		}
	}
`;

const StyledItemWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	&.main-menu {
		border-bottom: 1px solid ${({ theme }) => theme.colors.darkBlueAlphaShips};
		font-size: 17px;
		font-weight: ${({ theme }) => theme.fontWeight.bold};
		line-height: 23px;
		margin: 0 40px;
		padding: 15px 0;
		div {
			color: ${({ theme }) => theme.colors.white};
			cursor: pointer;
		}
		a {
			font-weight: ${({ theme }) => theme.fontWeight.bold};
			text-decoration: none;
			color: ${({ theme }) => theme.colors.white};
		}
	}
	&.submenu {
		color: ${({ theme }) => theme.colors.white};
		cursor: pointer;
		font-weight: 900;
		a {
			color: ${({ theme }) => theme.colors.white};
		}
	}
	&.third-submenu,
	&.activeThirdSubmenu {
		color: ${({ theme }) => theme.colors.white};
		cursor: pointer;
		a {
			color: ${({ theme }) => theme.colors.white};
		}
	}
	&.active {
		svg {
			transform: rotate(-90deg);
		}
	}
`;

const StyledArrowIconWrapper = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	margin-left: 15px;
	padding-right: 5px;
	width: 60px;
	svg {
		transform: rotate(90deg);
		transition: transform 200ms ease-in-out;
		path {
			stroke: ${({ theme }) => theme.colors.darkGreen};
		}
	}
`;

const StyledSubmenuList = styled.ul`
	background-color: ${({ theme }) => theme.colors.darkBlueAlphaShips};

	margin-top: -1px;
	padding: 0 40px;

	li {
		flex-direction: row;
	}
	&.first-submenu {
		border-top: 1px solid ${({ theme }) => theme.colors.darkBlueAlphaShips};
		font-size: 0.875rem;
		line-height: 1.1875rem;

		li {
			padding: 15px 0;

			&.active {
				padding-bottom: 0;
			}
		}
	}
	&.second-submenu {
		background-color: transparent;
		border-bottom: 1px solid ${({ theme }) => theme.colors.darkBlueAlphaShips};
		border-top: 1px solid ${({ theme }) => theme.colors.darkBlueAlphaShips};
		font-weight: ${({ theme }) => theme.fontWeight.regular};
		margin-top: 15px;
		padding-left: 20px;
		padding-right: 20px;
		a {
			font-weight: ${({ theme }) => theme.fontWeight.regular};
		}
	}
	&.third-submenu {
		//padding-top: 30px;
		background-color: transparent;
		margin-top: 15px;
		padding-left: 20px;
	}
`;
class MenuFullscreenMobile extends Component {
	state = {
		activeFirstSubmenu: -1,
		activeSecondSubmenu: -1,
		activeThirdSubmenu: -1,
		isOpenModal: false,
		linkTo: '',
	};

	componentDidUpdate(prevProps) {
		const { menuFullscreen } = this.props;
		const { activeFirstSubmenu, activeSecondSubmenu, activeThirdSubmenu } = this.state;

		if (prevProps.menuFullscreen !== menuFullscreen) {
			if (activeFirstSubmenu !== -1 || activeSecondSubmenu !== -1 || activeThirdSubmenu !== -1) {
				this.setState({
					activeFirstSubmenu: -1,
					activeSecondSubmenu: -1,
					activeThirdSubmenu: -1,
				});
			}
		}
	}

	render() {
		const { preparedSitemapData, menuFullscreen, globals } = this.props;
		const { activeFirstSubmenu, activeSecondSubmenu, activeThirdSubmenu, isOpenModal, linkTo } = this.state;
		const modalText = globals?.nodes?.[0]?.modalText || '';
		const btnClose = globals?.nodes?.[0]?.btnClose || '';
		const btnLinkTo = globals?.nodes?.[0]?.btnLinkTo || '';
		const closeConnectedSubmenus = (stateName) => {
			if (stateName === 'activeFirstSubmenu') {
				this.setState({ activeSecondSubmenu: -1, activeThirdSubmenu: -1 });
			} else if (stateName === 'activeSecondSubmenu') {
				this.setState({ activeThirdSubmenu: -1 });
			}
		};
		const setActiveSubmenu = (originalId, stateName) => {
			if (this.state[stateName] !== originalId) {
				this.setState({ [stateName]: originalId });
			} else {
				closeConnectedSubmenus(stateName);
				this.setState({ [stateName]: -1 });
			}
		};
		const togglePopup = () => {
			this.setState({ isOpenModal: !isOpenModal });
		};
		const showModal = (item) => {
			this.setState({ isOpenModal: true });
			this.setState({ linkTo: item.linkToExternalPage });
		};
		const ListItemEl = (item, stateName, className) => {
			const { dispatch, activeSite } = this.props;
			let linkOrDivEl = '';

			if (item.linkToExternalPage) {
				if (item.showModal) {
					linkOrDivEl = <div onClick={() => showModal(item)}>{item.menuItemTitle}</div>;
				} else {
					linkOrDivEl = (
						<a href={item.linkToExternalPage} target="_blank" rel="noreferrer">
							{item.menuItemTitle}
						</a>
					);
				}
			} else if (item.linkToPage) {
				if (item.isExistingPage) {
					linkOrDivEl = (
						<Link to={`/${getSlug(item.linkToPage, activeSite)}`} onClick={() => dispatch(setMenuFullscreen(false))}>
							{item.menuItemTitle}
						</Link>
					);
				} else linkOrDivEl = <div>{item.menuItemTitle}</div>;
			} else linkOrDivEl = <div>{item.menuItemTitle}</div>;

			return (
				<StyledItemWrapper className={className} onClick={() => setActiveSubmenu(item.originalId, stateName)}>
					{linkOrDivEl}
					{item.treeChildren.length !== 0 && (
						<StyledArrowIconWrapper>
							<ArrowIcon />
						</StyledArrowIconWrapper>
					)}
				</StyledItemWrapper>
			);
		};

		return (
			<>
				<StyledMenuWrapper className={menuFullscreen ? 'active' : ''}>
					{preparedSitemapData.map((item) => (
						<li key={item.originalId}>
							{ListItemEl(
								item,
								'activeFirstSubmenu',
								activeFirstSubmenu === item.originalId ? 'main-menu active' : 'main-menu',
							)}
							{/*	FIRST SUBMENU */}
							{activeFirstSubmenu === item.originalId && (
								<StyledSubmenuList className="first-submenu">
									{item.treeChildren.map((firstSubmenuItem) => (
										<li
											key={firstSubmenuItem.originalId}
											className={activeSecondSubmenu === firstSubmenuItem.originalId ? 'active' : ''}
										>
											{ListItemEl(
												firstSubmenuItem,
												'activeSecondSubmenu',
												activeSecondSubmenu === firstSubmenuItem.originalId ? 'submenu active' : 'submenu',
											)}
											{/*	SECOND SUBMENU */}
											{activeSecondSubmenu === firstSubmenuItem.originalId && (
												<StyledSubmenuList className="second-submenu">
													{firstSubmenuItem.treeChildren.map((secondSubmenuItem) => (
														<li key={secondSubmenuItem.originalId}>
															{ListItemEl(
																secondSubmenuItem,
																'activeThirdSubmenu',
																'activeThirdSubmenu',
																activeThirdSubmenu === secondSubmenuItem.originalId ? 'active' : '',
															)}
															{/*	THIRD SUBMENU */}
															{activeThirdSubmenu === secondSubmenuItem.originalId && (
																<StyledSubmenuList className="third-submenu">
																	{secondSubmenuItem.treeChildren.map((thirdSubmenuItem) => (
																		<li key={thirdSubmenuItem.originalId}>
																			{ListItemEl(thirdSubmenuItem)}
																		</li>
																	))}
																</StyledSubmenuList>
															)}
														</li>
													))}
												</StyledSubmenuList>
											)}
										</li>
									))}
								</StyledSubmenuList>
							)}
						</li>
					))}
				</StyledMenuWrapper>
				{isOpenModal && (
					<Popup
						show={isOpenModal}
						href={linkTo}
						close={() => togglePopup()}
						modalText={modalText}
						btnLinkTo={btnLinkTo}
						btnClose={btnClose}
					/>
				)}
			</>
		);
	}
}

export default connect((state) => ({
	menuFullscreen: state.menuFullscreen.menuFullscreen,
}))(MenuFullscreenMobile);
