import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '../../assets/inline/exit-btn.inline.svg';
import TextInfoHeader from '../typography/TextInfoHeader';
import Search from './index';
import { setSearchFullscreen } from '../../state/searchSlice';

const searchIndices = [{ name: `Gatsby`, title: `Gatsby` }];

const StyledSearchFullscreenWrapper = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.darkBlue};
	display: flex;
	flex-direction: column;
	height: 100vh;
	left: 0;
	padding: 20vh 0 0;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 106;
`;

const StyledSearchFullscreenContentWrapper = styled.div`
	max-width: 1440px;
	padding: 0 15px;
	width: 100%;
	@media (min-width: 768px) {
		padding: 0 30px;
	}
	@media (min-width: 1440px) {
		max-width: 1440px;
	}

	svg {
		line {
			stroke: var(--middle-color);
		}
	}
`;
const StyledTextHeader = styled.div`
	border-top: 2px solid ${({ theme }) => theme.colors.white};
	margin: 0 1rem;
	padding-top: 20px;
`;
const StyledClose = styled.div`
	cursor: pointer;
	position: absolute;
	right: 50px;
	top: 50px;
`;

const SearchMenuComponent = ({ globalData, activeSite }) => {
	const dispatch = useDispatch();
	const searchFullscreen = useSelector((state) => state.searchFullscreen.searchFullscreen);
	const searchText = globalData?.searchText || '';
	const searches = globalData?.searches || '';
	const searchInPortText = globalData?.searchInPortText || '';
	const searchFullscreenHandler = () => {
		dispatch(setSearchFullscreen(!searchFullscreen));
	};
	return (
		<StyledSearchFullscreenWrapper>
			<StyledSearchFullscreenContentWrapper>
				<StyledClose
					onClick={() => {
						searchFullscreenHandler();
					}}
				>
					<CloseIcon />
				</StyledClose>
				<StyledTextHeader>
					<TextInfoHeader className="white">{searchText}</TextInfoHeader>
				</StyledTextHeader>
				<Search activeSite={activeSite} searches={searches} indices={searchIndices} searchInPortText={searchInPortText} />
			</StyledSearchFullscreenContentWrapper>
		</StyledSearchFullscreenWrapper>
	);
};

export default SearchMenuComponent;
