import algoliasearch from 'algoliasearch/lite';
// eslint-disable-next-line import/no-named-default
import { createRef, default as React, useState, useMemo } from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import { ThemeProvider } from 'styled-components';
import StyledSearchBox from './styled-search-box';
import StyledSearchResult from './styled-search-result';
import StyledSearchRoot from './styled-search-root';
import useClickOutside from './use-click-outside';
import themeSettings from '../../styles/themeSettings';
//
// const theme = {
// 	foreground: '#000E41',
// 	background: '#FFF',
// 	faded: '#000E41',
// };

export default function Search({ indices, activeSite, searchInPortText, searches }) {
	const rootRef = createRef();
	const [query, setQuery] = useState();
	const [hasFocus, setFocus] = useState(false);
	const searchClient = useMemo(() => algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY), []);

	useClickOutside(rootRef, () => setFocus(false));
	return (
		<ThemeProvider theme={themeSettings}>
			<StyledSearchRoot ref={rootRef}>
				<InstantSearch searchClient={searchClient} indexName={indices[0].name} onSearchStateChange={({ query }) => setQuery(query)}>
					<StyledSearchBox activeSite={activeSite} onFocus={() => setFocus(true)} hasFocus searchInPortText={searchInPortText} />
					<StyledSearchResult searches={searches} show={query && query.length > 0 && hasFocus} indices={indices} />
				</InstantSearch>
			</StyledSearchRoot>
		</ThemeProvider>
	);
}
