import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { connect } from 'react-redux';

const StyledButtonContainer = styled.div`
	//display: block;
	background-color: transparent;
	display: flex;
	outline: 0;
	overflow: hidden;
	a {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
`;

const StyledButtonBox = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
	display: inline-block;
	height: 56px;
	outline: 0;
	overflow-y: hidden;
	padding: 0;
	position: relative;
	text-decoration: none;
	z-index: 1;
	&.mt-20 {
		margin-top: 20px;
	}
	&.mt-40 {
		margin-top: 40px;
	}
	&.mt-60 {
		margin-top: 60px;
		@media (max-width: 600px) {
			margin-top: 40px;
			margin-bottom: 30px;
		}
	}
	&.darkMode {
		position: fixed;
	}
	&.dark {
		div {
			border-color: var(--text-color-dark) !important;
			p {
				color: var(--text-color-dark) !important;
			}
		}
		&:hover {
			div {
				p {
					color: ${({ theme }) => theme.colors.green}!important;
				}
			}
		}
	}
	&.always-dark-blue {
		div {
			border-color: ${({ theme }) => theme.colors.darkBlue} !important;
			p {
				color: ${({ theme }) => theme.colors.darkBlue} !important;
			}
		}
	}
	&.dark-bg {
		div {
			background-color: ${({ theme }) => theme.colors.darkBlue};
			p {
				color: ${({ theme }) => theme.colors.white} !important;
			}
		}
	}

	&.white {
		div {
			border-color: ${({ theme }) => theme.colors.white}!important;
			p {
				color: ${({ theme }) => theme.colors.white}!important;
			}
		}
		&:hover {
			div {
				p {
					color: ${({ theme }) => theme.colors.green}!important;
				}
			}
		}
	}

	@media (min-width: 768px) {
		height: 68px;
	}
`;

const StyledText = styled.p`
	color: var(--text-color) !important;
	font-family: ${({ theme }) => theme.fontFamily.primary};
	font-size: 0.825rem !important;
	font-weight: ${({ theme }) => theme.fontWeight.black};
	letter-spacing: 1px;
	line-height: 1rem !important;
	text-transform: uppercase;
	transition: color 0.3s;
	@media (min-width: 768px) {
		font-size: 0.813rem;
	}
`;

const StyledTextWrapper = styled.div`
	border-bottom: 2px solid;
	border-color: var(--text-color) !important;
	border-top: 2px solid;
	padding: 12px 32px;
	transition: all 0.2s ease-in-out;

	@media (min-width: 768px) {
		padding: 16px 32px;
		&.animation {
			&:hover {
				padding: 24px 32px;
			}
		}
	}
`;

const ButtonRectangle = ({ className, href, children, to, pageTheme, action, wcagFontSizePlus }) => {
	return (
		<StyledButtonContainer>
			{href && (
				<StyledButtonBox className={` ${className}`} href={href} as="a" target="_blank" rel="noopener noreferrer">
					<StyledTextWrapper className={pageTheme !== 'contrastMode' ? 'animation' : ''}>
						<StyledText className="">{children}</StyledText>
					</StyledTextWrapper>
				</StyledButtonBox>
			)}

			{!href && to && (
				<StyledButtonBox className={`${className}`} as={Link} to={to}>
					<StyledTextWrapper className={pageTheme !== 'contrastMode' ? 'animation' : ''}>
						<StyledText className="">{children}</StyledText>
					</StyledTextWrapper>
				</StyledButtonBox>
			)}

			{!to && !href && (
				<StyledButtonBox className={`${className}`} onClick={action || null}>
					<StyledTextWrapper className={pageTheme !== 'contrastMode' ? 'animation' : ''}>
						<StyledText className="">{children}</StyledText>
					</StyledTextWrapper>
				</StyledButtonBox>
			)}
		</StyledButtonContainer>
	);
};

export default connect((state) => ({
	pageTheme: state.pageTheme.pageTheme,
	wcagFontSizePlus: state.wcagFontSizePlus.wcagFontSizePlus,
}))(ButtonRectangle);
