import React from 'react';
import styled from "styled-components";

const StyledBipWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-right: 18px;
	img {
		width: 45px;
		height: 20px;
	}
`;

const Bip = ({bipHref, bipIcon }) => {
	return (
		<StyledBipWrapper as="a" href={bipHref} target="_blank">
			<img alt="bip" src={bipIcon} />
		</StyledBipWrapper>
	);
};

export default Bip;