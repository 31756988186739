import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Typography from '../../typography/Typography';
import Button from '../Button';
import CloseIcon from '../../../assets/inline/exit-btn.inline.svg';

const StyledBackDrop = styled.div`
	height: 100vh;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000;
`;

const StyledModalWrapper = styled.div`
	background-color: #ffffff;
	border: 1px solid #04103b;
	height: ${({ wcagFontSizePlus }) => (wcagFontSizePlus ? '610px' : '410px')};
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: ${({ wcagFontSizePlus }) => (wcagFontSizePlus ? '810px' : '710px')};
	z-index: 2;
	@media (max-width: 1010px) {
		height: ${({ wcagFontSizePlus }) => (wcagFontSizePlus ? '600px' : '480px')};
		width: ${({ wcagFontSizePlus }) => (wcagFontSizePlus ? '730px' : '600px')};
	}
	@media (max-width: 768px) {
		height: ${({ wcagFontSizePlus }) => (wcagFontSizePlus ? '480px' : '380px')};
		width: 80%;
	}
`;

const StyledHeader = styled.h1`
	align-items: center;
	border-top: 2px solid rgba(0, 14, 65, 0.6);
	justify-content: center;
	padding-top: 20px;
`;

const StyledButton = styled.div`
	margin-right: 25px;
`;

const StyledModalVeil = styled.div`
	background-color: #04103b;
	height: 100%;
	left: 0;
	opacity: 1;
	position: absolute;
	top: 0;
	width: 100%;
`;

const StyledButtons = styled.div`
	bottom: 57px;
	display: flex;
	flex-direction: row;
	left: 40px;
	position: absolute;
	width: calc(100% - 80px);

	@media (min-width: 768px) {
		justify-content: flex-start;
		button {
			min-width: 180px;
			font-weight: ${({ theme }) => theme.fontWeight.black};
		}
	}
	@media (max-width: 468px) {
		justify-content: center;
		flex-direction: column;
		left: 30px;
		bottom: 40px;
		button {
			font-weight: ${({ theme }) => theme.fontWeight.black};
		}
	}
`;

const StyledModalContent = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	justify-content: center;
	padding: 45px 130px 0 40px;
	@media (max-width: 768px) {
		padding: 40px 30px 0 30px;
	}
`;

const StyledClose = styled.div`
	cursor: pointer;
	pointer-events: none;
	position: absolute;
	right: 50px;
	top: 50px;
	z-index: 1;
`;

const Popup = ({ close, btnClose, modalText, href, btnLinkTo, wcagFontSizePlus }) => {
	const modalVeil = useRef();
	useEffect(() => {
		gsap.fromTo([modalVeil.current], { opacity: 0.3 }, { opacity: 0.95, duration: 0.3 });
	});

	return (
		<StyledBackDrop className="my-modal-wrapper">
			<StyledClose>
				<CloseIcon />
			</StyledClose>
			<StyledModalWrapper className="my-modal-content" wcagFontSizePlus={wcagFontSizePlus}>
				<StyledModalContent>
					<StyledHeader>
						<Typography variant="12" fontWeight="light">
							{modalText}
						</Typography>
					</StyledHeader>
					<StyledButtons>
						<StyledButton>
							<Button action={close} className={btnLinkTo ? '' : 'dark-bg'}>
								{' '}
								{btnClose}{' '}
							</Button>
						</StyledButton>
						{btnLinkTo && (
							<a href={href} target="_blank" rel="noreferrer">
								<Button className="dark-bg">{btnLinkTo}</Button>
							</a>
						)}
					</StyledButtons>
				</StyledModalContent>
			</StyledModalWrapper>
			<StyledModalVeil onClick={close} className="modal-veil" ref={modalVeil} />
		</StyledBackDrop>
	);
};

export default connect((state) => ({
	wcagFontSizePlus: state.wcagFontSizePlus.wcagFontSizePlus,
}))(Popup);
