import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import getSlug from '../../utils/getSlug';
import { setLang } from '../../state/languageSlice';

const ChangeLanguageWrapper = styled.div`
	color: ${({ theme }) => theme.colors.white}; !important;
	font-size: 0.75rem;
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	height: 34px;
	opacity: 1;
	position: relative;
	text-transform: uppercase;
	width: 34px;


	svg {
		height: 34px;
		pointer-events: none;
		position: absolute;
		top: 0;
		width: 34px;
		.progress__circle {
			fill: none;
			stroke: ${({ isDarkSelector, theme }) => (isDarkSelector ? theme.colors.darkBlue : '#fff')}!important;
			stroke-width: 1px;
		}
	}
	&.visible {
		opacity: 1;
		pointer-events: all;
		transition: opacity 0.6s;
		transition-delay: 0.8s;
	}
	&.hidden {
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.2s;
	}
  
  @media (min-width: 1000px) {
	left: unset;
  }

  @media (min-width: 1024px) {
	height: 34px;
	width: 34px;
	svg {
	  height: 34px;
	  width: 34px;
	}
  }
 
  
`;

const CurrentLanguageInHiddenList = styled.div`
	align-items: center;
	background-color: ${({ pageTheme }) => (pageTheme === 'contrastMode' ? 'rgb(0,0,0)' : 'transparent')};
	border-radius: 50%;
	cursor: pointer;
	display: flex;
	height: 34px;
	justify-content: center;
	position: relative;
	width: 34px;
	&:hover {
		background-color: ${({ isDarkSelector }) => (isDarkSelector ? 'transparent' : '#fff')};
		span {
			color: ${({ pageTheme, theme }) => (pageTheme === 'contrastMode' ? 'rgb(0,0,0)' : theme.colors.darkBlue)};
		}
	}
	&:first-child {
		&:after {
			content: '';
			width: 1px;
			height: 10px;
			background-color: ${({ isDarkSelector, theme }) => (isDarkSelector ? theme.colors.darkBlue : '#fff')};
			position: absolute;
			top: -10px;
			left: 50%;
			@media (max-width: 1024px) {
				height: 3px;
				top: -3px;
			}
		}
		&:before {
			display: none;
		}
	}
	&.three-lang {
		&:first-child {
			&:after {
				content: '';
				width: 8px;
				height: 1px;
				background-color: ${({ isDarkSelector, theme }) => (isDarkSelector ? theme.colors.darkBlue : '#fff')};
				position: absolute;
				top: -3px;
				left: 20px;
				transform: rotate(-56deg);
			}
			&:before {
				content: '';
				width: 8px;
				height: 1px;
				background-color: ${({ isDarkSelector, theme }) => (isDarkSelector ? theme.colors.darkBlue : '#fff')};
				position: absolute;
				top: calc(50% + 2px);
				right: -7px;
				display: block;
			}
		}
		&:last-child {
			&:after {
				content: '';
				width: 8px;
				height: 1px;
				background-color: ${({ isDarkSelector, theme }) => (isDarkSelector ? theme.colors.darkBlue : '#fff')};
				position: absolute;
				top: -3px;
				right: 20px;
				transform: rotate(56deg);
			}
		}
	}

	span {
		font-size: 0.75rem;
		color: ${({ isDarkSelector, theme }) => (isDarkSelector ? theme.colors.darkBlue : '#fff')};
	}
	svg {
		height: 34px;
		left: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		width: 34px;
		.progress__circle {
			fill: none;
			stroke: ${({ isDarkSelector, theme }) => (isDarkSelector ? theme.colors.darkBlue : '#fff')};
			stroke-width: 1px;
		}
	}

	@media (min-width: 1024px) {
		height: 34px;
		width: 34px;
		background-color: ${({ pageTheme }) => (pageTheme === 'contrastMode' ? 'rgb(0,0,0)' : 'var(--menu-color)')};
		svg {
			height: 34px;
			width: 34px;
		}
	}
`;

const CurrentLanguage = styled.div`
	align-items: center;
	border-radius: 50%;
	cursor: pointer;
	display: flex;
	font-size: 0.75rem;
	height: 34px;
	justify-content: center;
	position: relative;
	width: 34px;
	z-index: 2;
	&:hover {
		background-color: ${({ isDarkSelector }) => (isDarkSelector ? 'transparent' : '#fff')};
		span {
			color: ${({ pageTheme, theme }) => (pageTheme === 'contrastMode' ? 'rgb(0,0,0)' : theme.colors.darkBlue)};
		}
	}
	svg {
		height: 34px;
		left: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		width: 34px;
	}

	span {
		color: ${({ isDarkSelector, theme }) => (isDarkSelector ? theme.colors.darkBlue : '#fff')};
		font-size: 0.75rem;
	}

	@media (min-width: 1000px) {
		left: unset;
	}
	@media (min-width: 1024px) {
		width: 34px;
		height: 34px;

		svg {
			height: 34px;
			width: 34px;
		}
	}
`;

const OtherLanguagesList = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	left: -20px;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: 18px;
	transition: transform 0.2s ease-in-out, opacity 0.1s ease-in-out;
	width: 74px;
	&.expanded {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		opacity: 1;
		pointer-events: all;
		transform: translateY(26px);
		transition: transform 0.2s ease-in-out, opacity 0.4s ease-in-out;
		z-index: 1;
	}
	&.three-lang {
		justify-content: space-between;
	}

	@media (min-width: 1024px) {
		transition: transform 0.3s 0.1s ease-in-out, opacity 0.3s 0.1s ease-in-out;
		&.expanded {
			transform: translateY(26px);
		}
	}
	@media (max-width: 1024px) {
		top: 10px;
	}
`;

const languageOptions = ['pl', 'en'];
// const languageOptions = ['pl', 'en'];

class LanguageSelectorNew extends React.Component {
	state = {
		isSelectorOpened: false,
	};

	shouldRemember = false;

	componentDidMount() {
		this.shouldRemember = Cookies.get('necessaryCookies');
		this.setLangFromBrowserOrLocalstorage();
		document.addEventListener('mousedown', this.handleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClick, false);
	}

	handleClick = (e) => {
		const { isSelectorOpened } = this.state;
		if (isSelectorOpened) {
			if (this.node?.contains(e.target)) {
				return null;
			}
			this.setState({ isSelectorOpened: false });
		}
		return null;
	};

	toggleSelectorState = () => {
		const { isSelectorOpened } = this.state;
		this.setState({ isSelectorOpened: !isSelectorOpened });
	};

	handleChange = (selectedOption, dispatch, pathToNavigate, lang) => {
		if (selectedOption !== lang) {
			if (this.shouldRemember) {
				window.localStorage.setItem('appUILang', selectedOption);
			}
			this.setStateToActiveSite(dispatch, selectedOption);
			this.toggleSelectorState();
			const slugToNavigate = getSlug(pathToNavigate, selectedOption);
			navigate(`/${slugToNavigate}`);
		}
	};

	setStateToActiveSite = (dispatch, site) => {
		if (site === 'en') {
			dispatch(setLang(languageOptions[1]));
		} else if (site === 'de') {
			dispatch(setLang(languageOptions[2]));
		} else {
			dispatch(setLang(languageOptions[0]));
		}
	};

	setLangFromBrowserOrLocalstorage = () => {
		const { lang, dispatch, otherPagesPathsForLangChange } = this.props;
		if (typeof window !== 'undefined' && otherPagesPathsForLangChange) {
			const localStorageLang = window.localStorage.getItem('appUILang');
			const language = localStorageLang && localStorageLang !== 'undefined' ? localStorageLang : window.navigator.language;
			if (language.includes('pl')) {
				const slugToNavigate = getSlug(otherPagesPathsForLangChange.pl, 'pl');
				navigate(`/${slugToNavigate}`);
				this.setStateToActiveSite(dispatch, 'pl');
				if (this.shouldRemember) {
					window.localStorage.setItem('appUILang', 'pl');
				}
			} else if ((language.includes('en') && lang !== 'en') || (language.includes('de') && lang !== 'de')) {
				const slugToNavigate = getSlug(otherPagesPathsForLangChange.en, 'en');
				navigate(`/${slugToNavigate}`);
				this.setStateToActiveSite(dispatch, 'en');
				if (this.shouldRemember) {
					window.localStorage.setItem('appUILang', 'en');
				}
			}
			// } else if (language.includes('de') && lang !== 'de') {
			// 	const slugToNavigate = getSlug(otherPagesPathsForLangChange.de, 'de');
			// 	navigate(`/${slugToNavigate}`);
			// 	this.setStateToActiveSite(dispatch, 'de');
			// 	if (this.shouldRemember) {
			// 		window.localStorage.setItem('appUILang', 'de');
			// 	}
			// }
		}
	};

	render() {
		const { lang, dispatch, otherPagesPathsForLangChange, necessaryCookies, pageTheme, isDarkSelector } = this.props;
		const { isSelectorOpened } = this.state;

		if (!this.shouldRemember) {
			this.shouldRemember = Cookies.get('necessaryCookies') || necessaryCookies;
		}

		const otherLanguages = languageOptions.filter((item) => item !== lang);
		const langSelectorClassName = languageOptions.length === 3 ? 'three-lang' : '';
		return (
			<>
				<ChangeLanguageWrapper
					isDarkSelector={isDarkSelector}
					/* eslint-disable-next-line no-return-assign */
					ref={(node) => (this.node = node)}
					className="change-lang"
				>
					<CurrentLanguage
						isDarkSelector={isDarkSelector}
						onClick={this.toggleSelectorState}
						className={pageTheme !== 'contrastMode' ? 'animation' : ''}
					>
						<span>{lang}</span>
						<svg aria-hidden="true" className="progress" width="70" height="70" viewBox="0 0 70 70">
							<path
								className="progress__circle"
								d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
													32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
							/>
						</svg>
					</CurrentLanguage>
					<OtherLanguagesList
						isDarkSelector={isDarkSelector}
						className={isSelectorOpened ? `expanded ${langSelectorClassName}` : langSelectorClassName}
					>
						{otherLanguages.map((otherLang) => (
							<CurrentLanguageInHiddenList
								key={otherLang}
								isDarkSelector={isDarkSelector}
								onClick={() => {
									this.handleChange(otherLang, dispatch, otherPagesPathsForLangChange[otherLang], lang);
								}}
								className={pageTheme !== 'contrastMode' ? `animation ${langSelectorClassName}` : langSelectorClassName}
							>
								<span>{otherLang}</span>
								<svg aria-hidden="true" className="progress" width="70" height="70" viewBox="0 0 70 70">
									<path
										className="progress__circle"
										d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,
													32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"
									/>
								</svg>
							</CurrentLanguageInHiddenList>
						))}
					</OtherLanguagesList>
				</ChangeLanguageWrapper>
			</>
		);
	}
}

export default connect((state) => ({
	lang: state.language.lang,
	pageTheme: state.pageTheme.pageTheme,
	necessaryCookies: state.necessaryCookies.necessaryCookies,
}))(LanguageSelectorNew);
