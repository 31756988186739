import styled from 'styled-components';
import React from 'react';

const StyledCheckbox = styled.label`
	background: transparent;
	border: 1px solid ${({ theme }) => theme.colors.white};
	border-radius: 34px;
	cursor: pointer;
	display: inline-block;
	font-size: 20px;
	height: 26px;
	position: relative;
	width: 50px;

	&.not-allowed {
		cursor: not-allowed;
	}
	&.dark-blue {
		border: 1px solid ${({ theme }) => theme.colors.darkBlue};
		div {
			background: ${({ theme }) => theme.colors.darkBlue};
		}
	}
	&.invalid {
	border: 1px solid red;
		div {
			background: red;
		}
	}
	input {
		display: none;
	}

	div {
		background: ${({ theme }) => theme.colors.white};
		border-radius: 50%;
		height: 21px;
		left: 2px;
		position: absolute;
		top: 50%;
		transform: translate3d(0, -50%, 0);
		transition: all 300ms;
		width: 21px;
	}

	&.active {
		border: 1px solid ${({ theme }) => theme.colors.darkGreen};
	}
	input:checked + div {
		background: ${({ theme }) => theme.colors.darkGreen};
		transform: translate3d(calc(100% + 1px), -50%, 0);
	}

	@media (min-width: 768px) {
		width: 70px;
		height: 34px;
		div {
			height: 27px;
			width: 27px;
			left: 3px;
		}
		input:checked + div {
			transform: translate3d(calc(100% + 8px), -50%, 0);
		}
	}
`;

const CustomCheckbox = ({ checked, name, onChange, isDisabled, colorSet, isInvalid }) => {

	return (
		<StyledCheckbox
			className={`${checked ? 'active' : ''} ${colorSet === 'darkBlue' ? 'dark-blue' : ''} ${isDisabled ? 'not-allowed' : ''} ${isInvalid ? 'invalid':''}`}
		>
			<input name={name || ''} type="checkbox" checked={checked} disabled={isDisabled} onChange={(e) => onChange(e)} />
			<div />
		</StyledCheckbox>
	);
};
export default CustomCheckbox;
