import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	margin: 0 auto;
	max-width: 100%;

	&.full-width {
		margin: 0;
		max-width: 100%;
		padding: 0;
		width: 100%;
	}
	.small-width {
		max-width: 960px;
		margin: 0 auto 0;
		padding: 0 15px;
		&.article {
			margin: 0 auto 5%;
		}
		@media (min-width: 966px) {
			padding: 0;
		}
		.full-width-to-left-map {
			width: 100vw;
			padding: 0;

			@media (min-width: 966px) and (max-width: 1366px) {
				margin-left: calc(-1 * (100vw - 950px) / 2);
				padding: 0;
			}
			@media (min-width: 1366px) {
				margin-left: calc(-1 * (100vw - 960px) / 2);
				padding: 0;
			}
		}
		.full-width-to-left {
			width: 100vw;
			padding: 0;
			margin-left: -15px;
			@media (min-width: 1366px) {
				margin-left: calc(-1 * (100vw - 960px) / 2);
			}
		}
		.full-width-like-inner-wrapper {
			width: calc(100vw - 30px);
			padding: 0;
			@media (min-width: 768px) {
				width: calc(100vw - 60px);
				margin-left: 15px;
			}
			@media (min-width: 966px) {
				margin-left: calc(-1 * (100vw - 60px - 960px) / 2);
			}
			@media (min-width: 1440px) {
				width: 1440px !important;
				margin-left: -240px;
			}
		}
	}

	&.content {
		margin-top: 160px;
	}
	&.content-single-news {
		margin-top: 300px;
	}
	.inner-wrapper {
		margin: 0 auto;
		max-width: 1440px;
		padding: 0 15px;
		@media (min-width: 768px) {
			padding: 0 30px;
		}
		@media (min-width: 1440px) {
			max-width: 1440px;
			padding: 0;
		}
	}
`;

const PageContainer = ({ children, className, id }) => {
	return (
		<Container className={className} id={id}>
			{children}
		</Container>
	);
};

export default PageContainer;
