import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import GlobalStyle from '../styles/GlobalStyles';
import themeSettings from '../styles/themeSettings';
import Menu from '../components/menu/Menu';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CookieInfo from '../components/cookiesInfo/CookiesInfo';
import FooterWrapper from '../components/footer/FooterWrapper';

const StyledLayoutWrapper = styled.div`
	width: 100vw;
`;
const StyledContentWrapper = styled.div`
	width: 100%;
`;

const Layout = ({ children, pageContext }) => {
	// const pageTheme = useSelector((state) => state.pageTheme.pageTheme);
	const menuFullscreen = useSelector((state) => state.menuFullscreen.menuFullscreen);
	// const dispatch = useDispatch();
	const activeSite = pageContext?.locale || '';
	const menuData = pageContext?.globals?.data?.menu || {};
	const globals = pageContext?.globals?.data?.globals || '';
	const cookiesData = pageContext?.globals?.data?.cookiesInfo || {};
	const preparedSitemapData = pageContext?.preparedSitemapData || [];
	const preparedSitemapSimplify = pageContext?.preparedSitemapSimplify || [];
	const isSingleNewsPage = pageContext?.isSingleNewsPage || false;
	const page = pageContext?.page || false;
	const slug = page?.slug || '';
	const isErrorPage = slug === '404';
	// const otherLangPages = pageContext?.otherLangPages || [];
	return (
		<ThemeProvider theme={themeSettings}>
			<GlobalStyle menuFullscreen={menuFullscreen} isErrorPage={isErrorPage} />
			<Menu
				menuData={menuData}
				activeSite={activeSite}
				globals={globals}
				slug={slug}
				isSingleNewsPage={isSingleNewsPage}
				// otherLangPageSlug={pageContext.otherLangPageSlug}
				otherPagesPathsForLangChange={pageContext.otherPagesPathsForLangChange}
				preparedSitemapData={preparedSitemapData}
				preparedSitemapSimplify={preparedSitemapSimplify}
			/>
			<StyledLayoutWrapper>
				<StyledContentWrapper>{children}</StyledContentWrapper>
				<CookieInfo data={cookiesData} />
			</StyledLayoutWrapper>
			<FooterWrapper
				footerData={menuData}
				globals={globals}
				activeSite={activeSite}
				otherPagesPathsForLangChange={pageContext.otherPagesPathsForLangChange}
				preparedSitemapSimplify={preparedSitemapSimplify}
			/>
		</ThemeProvider>
	);
};

export default Layout;
