import React from 'react';
import styled from "styled-components";

const StyledSingleSocialMediaWrapper = styled.a`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	margin-right: 20px;
	img {
		height: 18px;
		width: 18px;
	}
`;

const SingleSocialMediaItem = ({socialMediaUrl, socialMediaIcon, isFooter, socialMediaDarkIcon }) => {
	const iconSource = isFooter ? socialMediaDarkIcon : socialMediaIcon;
	return (
		<StyledSingleSocialMediaWrapper href={socialMediaUrl} target="_blank" rel="noreferrer">
			<img alt="social-media" src={iconSource} />
		</StyledSingleSocialMediaWrapper>
	);
};

export default SingleSocialMediaItem;