import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import SearchIcon from '../../assets/search-icon.svg';

export default connectSearchBox(({ refine, currentRefinement, className, onFocus, searchInPortText }) => (
	<form className={className}>
		<input
			className="SearchInput"
			type="text"
			placeholder={searchInPortText}
			aria-label="Search"
			onChange={(e) => refine(e.target.value)}
			value={currentRefinement}
			onFocus={onFocus}
		/>
		<img className="SearchIcon" style={{ width: '25px', height: '25px' }} alt="magnifier" src={SearchIcon} />
		{/* <SearchIcon className="SearchIcon" /> */}
	</form>
));
