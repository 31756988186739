import { createSlice } from '@reduxjs/toolkit';

export const pageThemeSlice = createSlice({
	name: 'pageTheme',
	initialState: {
		pageTheme: 'lightMode',
	},
	reducers: {
		setPageTheme: (state, action) => {
			state.pageTheme = action.payload;
		},
	},
});

export const { setPageTheme } = pageThemeSlice.actions;

export default pageThemeSlice.reducer;
