import { createSlice } from '@reduxjs/toolkit';

export const searchFullscreenSlice = createSlice({
	name: 'SearchFullscreen',
	initialState: {
		searchFullscreen: false,
	},
	reducers: {
		setSearchFullscreen: (state) => {
			state.searchFullscreen = !state.searchFullscreen;
		},
	},
});

export const { setSearchFullscreen } = searchFullscreenSlice.actions;

export default searchFullscreenSlice.reducer;
