const themeSettings = {
	colors: {
		black: 'rgba(0, 0, 0, 1)',
		white: 'rgba(255, 255, 255, 1)',
		green: 'rgba(0, 255, 236, 1)',
		greyLight: `rgba(222, 222, 223, 1)`,
		greenAlpha32: `rgba(44,254,235, 0.32)`,
		grey: `rgba(239, 239, 243, 1)`,
		lightGrey: `rgba(180,183,196,1)`,
		darkGrey: 'rgba(81, 81, 103, 1)',
		lightBlue: 'rgba(81, 186, 214, 1)',
		// azureBlue: 'rgba(190, 231, 247, 1)',
		darkGreen: 'rgb(0,240,222)',
		azureBlue: 'rgba(204, 236, 249, 1)',
		darkAzureBlue: 'rgb(85,169,205)',
		darkBlue: 'rgb(0,14,65)',
		darkBlueAlpha90: 'rgba(0, 14, 65, 0.9)',
		darkBlueAlpha: 'rgba(0, 14, 65, 0.6)',
		darkBlueAlphaShips: 'rgba(3, 50, 96, 0.7)',
		darkBlueAlphaBanner: 'rgba(3, 50, 96, 0.6)',
		darkBlueAnimation: 'rgba(7,20,69,1)',
		ukrainianBlue: 'rgba(13, 91, 178, 1)',
		ukrainianYellow: 'rgba(246, 205, 46, 1)',
	},

	fontFamily: {
		primary: 'Strawford, sans-serif',
		secondary: 'Roboto Slab, serif',
	},

	fontWeight: {
		thin: 100,
		extraLight: 200,
		light: 300,
		regular: 400,
		medium: 500,
		semiBold: 600,
		bold: 700,
		extraBold: 800,
		black: 900,
	},
};

export default themeSettings;
