import { createSlice } from '@reduxjs/toolkit';

export const menuFullscreenSlice = createSlice({
	name: 'MenuFullscreen',
	initialState: {
		menuFullscreen: false,
	},
	reducers: {
		setMenuFullscreen: (state, action) => {
			state.menuFullscreen = action.payload;
		},
	},
});

export const { setMenuFullscreen } = menuFullscreenSlice.actions;

export default menuFullscreenSlice.reducer;
