import styled, { css } from 'styled-components';
import SearchBox from './search-box';

const open = css`
	//width: 10em;
	background: ${({ theme }) => theme.colors.white};
	cursor: text;
	margin-left: -1.6em;
	padding-left: 50px;
`;

const closed = css`
	background: transparent;
	color: ${({ theme }) => theme.colors.grey};
	cursor: pointer;
	font-size: 1.25rem;
	margin-left: -1em;
	padding-left: 1em;
	width: 0;
`;

export default styled(SearchBox)`
	//box-shadow: 0px 0px 11px 2px rgba(204, 236, 249, 0.65);
	align-items: center;
	display: flex;
	flex-direction: row-reverse;
	margin-bottom: 0;
	margin-top: 70px;

	.SearchInput {
		//border: 2px solid ${({ theme }) => theme.colors.darkAzureBlue};
		//border: ${({ hasFocus }) => (hasFocus ? 'auto' : 'none')};
		border: none;
		border-radius: 2px;
		color: ${({ theme }) => theme.colors.darkBlue};
		font-size: 1.25rem;
		outline: none;
		padding: 20px 50px;
		transition: 100ms;
		width: 100%;
		::placeholder {
			color: ${({ theme }) => theme.colors.lightGrey};
			font-weight: ${({ theme }) => theme.fontWeight.medium};
		}
		${({ hasFocus }) => (hasFocus ? open : closed)}
	}

	.SearchIcon {
		color: ${({ theme }) => theme.colors.black};
		margin-right: -10px;
	}
`;
