import React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const Text = styled.div`
	color: var(--text-color) !important;
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 1.875rem;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	line-height: 2.625rem;
	margin: 0 0 30px;
	transition: color 0.3s;
	@media (min-width: 768px) {
		font-size: 4.375rem;
		line-height: 5.875rem;
	}
	&.dark {
		color: var(--text-color-dark) !important;
	}
	&.center {
		text-align: center;
	}
	&.white {
		color: ${({ theme }) => theme.colors.white}!important;
	}
	&.black {
		font-weight: ${({ theme }) => theme.fontWeight.black};
	}
`;

const TextInfoHeader = ({ className, children, style }) => {
	return <Text style={style} className={className} dangerouslySetInnerHTML={getHtmlFromRedactorField(children)} />;
};

export default TextInfoHeader;
