import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import getSlug from '../../../utils/getSlug';

const StyledUeFlagWrapper = styled(Link)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-right: 15px;
	@media (min-width: 1600px) {
		margin-right: 40px;
	}
	img {
		height: 34px;
		width: 51px;
	}
	&.footer-mobile {
		margin-right: 0;
	}
`;

const EuFlag = ({ preparedSlug, activeSite, ueFlagIcon, isFooter }) => {
	return (
		<StyledUeFlagWrapper className={isFooter ? 'footer-mobile' : ''} to={`/${getSlug(preparedSlug, activeSite)}`}>
			<img alt="ue-flag" src={ueFlagIcon} />
		</StyledUeFlagWrapper>
	);
};

export default EuFlag;
