import React from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { setPageTheme } from '../../../state/pageThemeSlice';

const StyledModeWrapper = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
	height: 100%;
	margin-right: 15px;
	img {
		height: 24px;
	}
`;

const StyledModeSwitcherWrapper = styled.div`
	align-items: center;
	display: flex;
	.slider {
		bottom: 0;
		cursor: pointer;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		transition: transform 0.25s ease-in-out, background 0s ease-in-out 0.1s;
	}
	input:checked + .slider:before {
		transform: translateX(32px);
		background-image: ${({ isSticky, menuFullscreen, iconMoon, iconMoonDark }) =>
		isSticky || menuFullscreen ? `url(${iconMoonDark})` : `url(${iconMoon})`};
	}
	.slider:before {
		background-image: ${({ isSticky, menuFullscreen, iconSun, iconSunDark }) =>
		isSticky || menuFullscreen ? `url(${iconSunDark})` : `url(${iconSun})`};
		background-repeat: no-repeat;
		bottom: 3px;
		content: '';
		height: 26px;
		left: 4px;
		position: absolute;
		transition: transform 0.25s ease-in-out, background 0s ease-in-out 0.1s;
		width: 26px;
		border: ${({isDarkBorder, theme}) => isDarkBorder ? `1px solid ${  theme.colors.darkBlue}` : 'none'};
	}
	.slider.round:before {
		border-radius: 50%;
	}
`;

const StyledSwitcherLabel = styled.label`
	display: inline-block;
	height: 34px;
	position: relative;
	width: 68px;
`;

const StyledSwitcherInput = styled.input`
	display: none;
`;

const StyledCircle = styled.div`
	background-color: transparent;
	background-image: ${({ isSticky, menuFullscreen, dayBackground }) => (isSticky || menuFullscreen ? `url(${dayBackground})` : '')};
	border: ${({ isDarkBorder, isSticky, menuFullscreen, theme }) => (isDarkBorder ? `1px solid ${  theme.colors.darkBlue}` : isSticky || menuFullscreen ? 'none' : '1px solid white')};
	border-radius: 34px;
	&.night {
		background-image: ${({ isSticky, menuFullscreen, nightBackground }) =>
		isSticky || menuFullscreen ? `url(${nightBackground})` : ''};
	}
`;

const ModeSwitcher = ({ pageTheme, isSticky, menuFullscreen, globals, isDarkBorder }) => {
	const iconMoon = globals?.moonIcon?.url || '';
	const iconMoonDark = globals?.moonDarkIcon?.url || '';
	const iconSun = globals?.sunIcon?.url || '';
	const iconSunDark = globals?.sunDarkIcon?.url || '';
	const nightBackground = globals?.nightBackgroundIcon?.url || '';
	const dayBackground = globals?.dayBackgroundIcon?.url || '';

	const dispatch = useDispatch();
	const pageThemeHandler = () => {
		if (pageTheme === 'lightMode') {
			dispatch(setPageTheme('darkMode'));
		} else {
			dispatch(setPageTheme('lightMode'));
		}
	};
	const isDarkMode = pageTheme === 'darkMode';
	return (
		<StyledModeWrapper>
			<StyledModeSwitcherWrapper
				isDarkBorder={isDarkBorder}
				isSticky={isSticky}
				menuFullscreen={menuFullscreen}
				iconMoon={iconMoon}
				iconMoonDark={iconMoonDark}
				iconSun={iconSun}
				iconSunDark={iconSunDark}
				className="theme-switch-wrapper"
			>
				<StyledSwitcherLabel className="theme-switch" htmlFor="theme-checkbox">
					<StyledSwitcherInput
						className={isDarkMode ? 'checked' : 'no-checked'}
						onChange={() => pageThemeHandler()}
						type="checkbox"
						id="theme-checkbox"
						checked={isDarkMode}
					/>
					<StyledCircle
						isDarkBorder={isDarkBorder}
						isSticky={isSticky}
						menuFullscreen={menuFullscreen}
						nightBackground={nightBackground}
						dayBackground={dayBackground}
						className={isDarkMode ? 'night slider round' : 'slider round'}
					/>
				</StyledSwitcherLabel>
			</StyledModeSwitcherWrapper>
		</StyledModeWrapper>
	);
};

export default connect((state) => ({
	pageTheme: state.pageTheme.pageTheme,
	menuFullscreen: state.menuFullscreen.menuFullscreen,
}))(ModeSwitcher);
