import React from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from "react-redux";
import {setWcagFontSizePlus} from '../../../state/wcagFontSizePlusSlice';

const StyledWcagSettings = styled.div`
	align-items: center;
	display: flex;
	height: 100%;
	margin-right: 18px;
	position: relative;
	div {
		cursor: pointer;
	}
`;

const StyledWcagLettersWrapper = styled.div`
	align-items: center;
	border: 1px solid white;
	border-radius: 17px;
	display: flex;
	flex-direction: row;
	height: 34px;
	justify-content: space-around;
	position: relative;
	width: 68px;
	&:before {
		content: '';
		position: absolute;
		border-radius: 50%;
		transition: transform 0.3s ease-in-out;
		transform: translateX(0);	
		background-color: ${({theme}) => theme.colors.white};
		height: 25px;
		left: 4px;
		width: 25px;
		z-index: 0;
		border: 1px solid ${({theme, isFooter}) => isFooter ? theme.colors.darkBlue : 'transparent'};		
	}
	&.active {
		&:before {
			transition: transform 0.3s ease-in-out;
			transform: translateX(32px);
		}
	}
	&.dark-blue {
		border: 1px solid ${({theme}) => theme.colors.darkBlue};
	}
`;

const StyledWcagItem = styled.div`
	-ms-flex-pack: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	img {
		height: 25px;
		width: 25px;
		z-index: 1;
	}
`;

const WcagFontSizeSwitcher = ({ isDarkMode, wcagAIcon, wcagAIconDarkMode, wcagAPlusIcon, wcagAPlusIconDarkMode, wcagFontSizePlus, isFooter }) => {
	const dispatch = useDispatch();
	const setGlobalFontSize = (fontSize) => {
		document.documentElement.style.setProperty('--font-size', `${fontSize}px`);
	};
	const handleWcagClick = () => {
		if (wcagFontSizePlus) {
			dispatch(setWcagFontSizePlus(false))
			setGlobalFontSize(16);
		} else {
			dispatch(setWcagFontSizePlus(true))
			setGlobalFontSize(25);
		}
	};
	return (
		<StyledWcagSettings>
			<StyledWcagLettersWrapper className={wcagFontSizePlus && isFooter ? 'dark-blue active' : !wcagFontSizePlus && isFooter ? 'dark-blue' : wcagFontSizePlus && !isFooter ? 'active' : ''}>
				<StyledWcagItem onClick={() => handleWcagClick()} isDarkMode={isDarkMode} isFooter={isFooter}>
					<img src={wcagFontSizePlus && !isFooter ? wcagAIconDarkMode : wcagAIcon} alt="wcag-a" />
				</StyledWcagItem>
				<StyledWcagItem
					onClick={() => handleWcagClick()}
					isDarkMode={isDarkMode}
					isFooter={isFooter}
				>
					<img src={!wcagFontSizePlus && !isFooter ? wcagAPlusIconDarkMode : wcagAPlusIcon} alt="wcag-a-plus" />
				</StyledWcagItem>
			</StyledWcagLettersWrapper>
		</StyledWcagSettings>
	);
};

export default connect((state) => ({
	wcagFontSizePlus: state.wcagFontSizePlus.wcagFontSizePlus,
}))(WcagFontSizeSwitcher);
