import React from 'react';
import PageContainer from "../common/PageContainer";
import FooterDesktopNew from "./FooterDesktopNew";
import FooterMobileNew from "./FooterMobileNew";

const FooterWrapper = ({ footerData, globals, activeSite, otherPagesPathsForLangChange, preparedSitemapSimplify }) => {
	return (
		<PageContainer id="footer" className="full-width">
			<FooterMobileNew
				footerData={footerData}
				globals={globals}
				activeSite={activeSite}
				otherPagesPathsForLangChange={otherPagesPathsForLangChange}
				preparedSitemapSimplify={preparedSitemapSimplify}
			/>
			<FooterDesktopNew
				footerData={footerData}
				globals={globals}
				activeSite={activeSite}
				otherPagesPathsForLangChange={otherPagesPathsForLangChange}
				preparedSitemapSimplify={preparedSitemapSimplify}
			/>
		</PageContainer>
	);
};

export default FooterWrapper;
