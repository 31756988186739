import React, { Component } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import CookieInfoOptionsModal from '../cookiesInfoOptionsModal/CookiesInfoOptionsModal';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import { setNecessaryCookies } from '../../state/necessaryCookiesSlice';
import Button from '../common/Button';

const StyledCookieInfoWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	border: 2px solid ${({ theme }) => theme.colors.darkBlue};
	bottom: 5px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	left: 5px;
	max-width: 700px;
	opacity: 0;
	position: fixed;
	transform: translateX(-100%);
	transition: transform 0.3s ease-in-out, opacity 0.5s ease-in-out;
	width: calc(100vw - 10px);
	z-index: 200;
	&.visible {
		opacity: 1;
		transform: translateX(0);
	}
	@media (min-width: 768px) {
		bottom: 10px;
		left: 10px;
	}
`;

const StyledInfoImg = styled.img`
	height: 80px;
	margin-bottom: 10px;
	width: 80px;

	@media (min-width: 768px) {
		width: 90px;
		height: 90px;
		margin-bottom: 30px;
	}
`;

const StyledInfoWrapper = styled.div`
	color: ${({ theme }) => theme.colors.darkBlue};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 10px 15px;
	width: 100%;
	@media (min-width: 768px) {
		padding: 50px calc(100vw / 24 - 10px);
	}
`;

const StyledInfoHeader = styled.div`
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 1.875rem;
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	line-height: 4rem;
	@media (min-width: 768px) {
		font-size: 3.125rem;
	}
`;

const StyledInfoText = styled.div`
	font-size: 0.875rem;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 1.5rem;
	margin: 0 0 35px;
	max-width: 600px;

	@media (min-width: 768px) {
		font-size: 1.125rem;
		line-height: 1.75rem;
		margin: 10px 0 40px;
	}
`;

const CookiesButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;

	div {
		width: 250px;
	}
	@media (min-width: 768px) {
		flex-direction: row;

		div {
			width: auto;
		}
		.config {
			margin-right: 35px;
		}
	}
`;

class CookieInfo extends Component {
	state = {
		showCookieInfo: false,
		isCookiesModalVisible: false,
		cookiesSettings: {
			necessaryCookies: true,
			isGoogleAnalytics: false,
			isFacebookPixel: false,
			isGoogleTagManager: false,
		},
	};

	componentDidMount() {
		this.setState({ showCookieInfo: Cookies.get('necessaryCookies') === undefined });
	}

	componentDidUpdate(prevProps, prevState) {
		const { isCookiesModalVisible } = this.state;

		if (isMobile && prevState.isCookiesModalVisible !== isCookiesModalVisible) {
			if (isCookiesModalVisible) {
				document.documentElement.style.overflowY = 'hidden';
			} else {
				document.documentElement.style.overflowY = 'auto';
			}
		}
	}

	setCookies = () => {
		const { dispatch } = this.props;
		const { cookiesSettings } = this.state;
		dispatch(setNecessaryCookies(true));
		Cookies.set('necessaryCookies', cookiesSettings.necessaryCookies);
		Cookies.set('gatsby-gdpr-google-analytics', cookiesSettings.isGoogleAnalytics);
		Cookies.set('gatsby-gdpr-facebook-pixel', cookiesSettings.isFacebookPixel);
		Cookies.set('gatsby-gdpr-google-tagmanager', cookiesSettings.isGoogleTagManager);
		const location = window.location.href;
		initializeAndTrack(location);
	};

	render() {
		const { showCookieInfo, isCookiesModalVisible, cookiesSettings } = this.state;
		const { data } = this.props;

		const moduleData = data?.nodes?.[0] || '';
		const img = moduleData?.image?.url || '';
		const header = moduleData?.header || '';
		const content = moduleData?.content || '';
		const settingsBtnText = moduleData?.settingsButtonText || '';
		const acceptAllBtnText = moduleData?.acceptAllButtonText || '';

		const modalData = {
			titleFirstTab: moduleData?.titleFirstTab || '',
			advancedContent: moduleData?.advancedContent || '',
			titleSecondTab: moduleData?.titleSecondTab || '',
			googleAnalyticsText: moduleData?.googleAnalyticsText || '',
			googleAnalyticsDescription: moduleData?.googleAnalyticsDescription || '',
			googleAnalyticsLink: moduleData?.googleAnalyticsLink || '',
			facebookPixelText: moduleData?.facebookPixelText || '',
			facebookPixelDescription: moduleData?.facebookPixelDescription || '',
			facebookPixelLink: moduleData?.facebookPixelLink || '',
			googleTagManagerText: moduleData?.googleTagManagerText || '',
			googleTagManagerDescription: moduleData?.googleTagManagerDescription || '',
			googleTagManagerLink: moduleData?.googleTagManagerLink || '',
			necessaryCookiesText: moduleData?.necessaryCookiesText || '',
			necessaryCookiesDescription: moduleData?.necessaryCookiesDescription || '',
			necessaryCookiesLink: moduleData?.necessaryCookiesLink || '',
			acceptAllBtnText,
			cancelBtnText: moduleData?.cancelButtonText || '',
			acceptSelectedBtnText: moduleData?.acceptSelectedButtonText || '',
			customizeBtnText: moduleData?.customizeButtonText || '',
		};

		const setSettingsInitial = () => {
			this.setState({
				cookiesSettings: {
					necessaryCookies: true,
					isGoogleAnalytics: false,
					isFacebookPixel: false,
					isGoogleTagManager: false,
				},
			});
		};

		const toggleCookiesInfoOptionsModal = () => {
			if (isCookiesModalVisible) {
				setSettingsInitial();
			}
			this.setState({ isCookiesModalVisible: !isCookiesModalVisible, showCookieInfo: !showCookieInfo });
		};

		const changeSettings = (propertyName, value) => {
			this.setState((prevState) => ({
				cookiesSettings: {
					...prevState.cookiesSettings,
					[propertyName]: value,
				},
			}));
		};

		const saveCookiesOptions = () => {
			this.setState({ showCookieInfo: false, isCookiesModalVisible: false });
			this.setCookies();
		};

		const acceptAll = () => {
			this.setState(
				{
					showCookieInfo: false,
					isCookiesModalVisible: false,
					cookiesSettings: {
						necessaryCookies: true,
						isGoogleAnalytics: true,
						isFacebookPixel: true,
						isGoogleTagManager: true,
					},
				},
				() => this.setCookies(),
			);
		};

		return (
			<>
				{isCookiesModalVisible && (
					<CookieInfoOptionsModal
						modalData={modalData}
						saveCookiesOptions={saveCookiesOptions}
						cookiesSettings={cookiesSettings}
						changeSettings={changeSettings}
						acceptAll={acceptAll}
						toggleCookiesInfoOptionsModal={toggleCookiesInfoOptionsModal}
					/>
				)}

				<StyledCookieInfoWrapper data-cursor="black" className={showCookieInfo ? 'visible' : ''}>
					<StyledInfoWrapper>
						<StyledInfoImg src={img} />
						<StyledInfoHeader>{header}</StyledInfoHeader>
						<StyledInfoText dangerouslySetInnerHTML={getHtmlFromRedactorField(content)} />
						<CookiesButtonWrapper>
							<Button className="config always-dark-blue" action={toggleCookiesInfoOptionsModal}>
								{settingsBtnText}
							</Button>
							<Button action={acceptAll} className="dark-bg">
								{acceptAllBtnText}
							</Button>
						</CookiesButtonWrapper>
					</StyledInfoWrapper>
				</StyledCookieInfoWrapper>
			</>
		);
	}
}
export default connect((state) => ({
	necessaryCookies: state.necessaryCookies.necessaryCookies,
}))(CookieInfo);
