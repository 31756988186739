import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import ModeSwitcher from '../common/menuAndFooter/ModeSwitcher';
import LanguageSelectorNew from '../languageSelector/LanguageSelectorNew';
import getPreparedSlug from '../../utils/getPreparedSlug';
import ContrastSwitcher from '../common/menuAndFooter/ContrastSwitcher';
import WcagFontSizeSwitcher from '../common/menuAndFooter/WcagFontSizeSwitcher';
import Bip from '../common/menuAndFooter/Bip';
import EuFlag from '../common/menuAndFooter/EuFlag';
import SingleSocialMediaItem from '../common/menuAndFooter/SingleSocialMediaItem';
import Magnifier from '../../assets/magnifier-white.svg';

const StyledMenuIconsWrapper = styled.div`
	//opacity: 0;
	//transform: translateX(0%) translateY(100%);
	//transition: opacity 0.1s 0.3s cubic-bezier(0.77, 0, 0.175, 1);
	align-items: flex-end;
	background-color: var(--menu-color);
	bottom: 0;
	display: none;
	flex-direction: column;
	height: 90px;
	justify-content: center;
	left: 0;
	padding: 0 25px;
	position: fixed;
	top: unset;
	width: 100vw;

	&.active {
		//opacity: 1;
		//transform: translateX(0%) translateY(0%);
		display: flex;
		@media (min-width: 1366px) {
			display: flex;
			visibility: hidden;
		}
	}

	&.sticky {
		justify-content: center;
	}

	@media (min-width: 1366px) {
		background-color: transparent;
		display: flex;
		position: static;
		//width: calc((100% - 226px) / 2 - 5px);
		width: calc((100% - 226px) / 2);
		height: 100%;
		justify-content: space-between;
		padding: 0;
	}
`;

const StyledIconsWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	@media (min-width: 1366px) and (max-width: 1439px) {
		flex-wrap: wrap;
		padding-left: 25px;
		margin-top: -16px;
	}
	&.isSticky {
		flex-wrap: nowrap;
		margin-top: 0;
	}
`;

const StyledSocialMediaWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	height: 100%;
	justify-content: flex-start;
	margin-right: 0;
	@media (min-width: 1366px) {
		margin-right: 0;
	}
	@media (min-width: 1600px) {
		margin-right: 35px;
	}
`;

const StyledSearchWrapper = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.colors.white};
	display: none;
	flex-direction: row;
	height: 26px;
	justify-content: center;
	transition: display 0.3s ease-in-out;
	width: 250px;
	&.hidden {
		display: none;
	}
	&.sticky {
		width: 200px;
		margin-right: 50px;
	}
	p {
		//padding-right: 10px;
		color: ${({ theme }) => theme.colors.white};
		font-family: ${({ theme }) => theme.fontFamily.primary};
		font-size: 0.8125rem;
		font-weight: ${({ theme }) => theme.fontWeight.bold};
		letter-spacing: 1px;
		line-height: 1rem;
		text-transform: uppercase;
	}

	@media (min-width: 1366px) {
		display: flex;
	}
`;

const StyledMagnifierContainer = styled.div`
	//border-bottom: 2px solid ${({ theme }) => theme.colors.white};
	//width: 100%;
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 45px;
	min-width: 200px;
	padding: 0 16px 8px 20px;
	div {
		color: ${({ theme }) => theme.colors.white};
		font-size: 1rem;
		font-weight: ${({ theme }) => theme.fontWeight.bold};
	}

	&.hidden {
		display: none;
	}
	&.sticky {
		margin-right: 40px;
		margin-top: 0;
		width: 200px;
		@media (min-width: 1366px) and (max-width: 1660px) {
			min-width: 172px;
			width: 172px;
			margin-right: 20px;
			padding: 0 8px 8px 10px;
		}
	}
`;

const MenuIconsBar = ({
	isStickyMode,
	pageTheme,
	menuFullscreen,
	globalData,
	searchFullscreenHandler,
	socialMediaData,
	otherPagesPathsForLangChange,
	searchInPortText,
	necessaryCookies,
	preparedSitemapSimplify,
	activeSite,
}) => {
	const bipIcon = globalData?.bipIcon?.url || '';
	const bipHref = globalData?.bipUrl || '';
	const contrastOnIcon = globalData?.contrastOnIcon?.url || '';
	const contrastOffIcon = globalData?.contrastOffIcon?.url || '';
	const wcagAIcon = globalData?.wcagAIcon?.url || '';
	const wcagAIconDarkMode = globalData?.wcagAIconDarkMode?.url || '';
	const wcagAPlusIcon = globalData?.wcagAPlusIcon?.url || '';
	const wcagAPlusIconDarkMode = globalData?.wcagAPlusIconDarkMode?.url || '';
	const ueFlagIcon = globalData?.ueFlagIcon?.url || '';
	const ueFlagUrlId = globalData?.ueFlagUrl?.id || '';
	const isDarkMode = pageTheme === 'darkMode';
	const preparedSlug = getPreparedSlug(ueFlagUrlId, preparedSitemapSimplify);

	return (
		<StyledMenuIconsWrapper className={isStickyMode && !menuFullscreen ? 'sticky' : menuFullscreen ? 'active' : ''}>
			<StyledIconsWrapper className={isStickyMode ? 'isSticky' : ''}>
				<StyledMagnifierContainer className="sticky" onClick={() => searchFullscreenHandler()}>
					<div>{searchInPortText}</div>
					<img alt="magnifier" src={Magnifier} />
				</StyledMagnifierContainer>
				{!isStickyMode && (
					<>
						<WcagFontSizeSwitcher
							isDarkMode={isDarkMode}
							wcagAIcon={wcagAIcon}
							wcagAIconDarkMode={wcagAIconDarkMode}
							wcagAPlusIcon={wcagAPlusIcon}
							wcagAPlusIconDarkMode={wcagAPlusIconDarkMode}
						/>
						<ContrastSwitcher pageTheme={pageTheme} contrastOnIcon={contrastOnIcon} contrastOffIcon={contrastOffIcon} />
						<Bip bipHref={bipHref} bipIcon={bipIcon} />
						<EuFlag preparedSlug={preparedSlug} activeSite={activeSite} ueFlagIcon={ueFlagIcon} />
					</>
				)}
				{isStickyMode && (
					<StyledSocialMediaWrapper>
						{socialMediaData.map((item) => {
							const socialMediaUrl = item?.socialMediaLink || '';
							const socialMediaIcon = item?.icon?.url || '';
							return (
								<SingleSocialMediaItem
									key={socialMediaUrl}
									socialMediaUrl={socialMediaUrl}
									socialMediaIcon={socialMediaIcon}
								/>
							);
						})}
					</StyledSocialMediaWrapper>
				)}
				<ModeSwitcher isSticky={isStickyMode} globals={globalData} />
				<LanguageSelectorNew otherPagesPathsForLangChange={otherPagesPathsForLangChange} necessaryCookies={necessaryCookies} />
			</StyledIconsWrapper>
		</StyledMenuIconsWrapper>
	);
};

export default connect((state) => ({
	pageTheme: state.pageTheme.pageTheme,
}))(MenuIconsBar);
