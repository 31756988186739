import { default as React } from 'react';
import { connectStateResults, Highlight, Hits, Index, Snippet, PoweredBy, Configure } from 'react-instantsearch-dom';
import { navigate } from 'gatsby-link';
import { connect, useDispatch } from 'react-redux';
import { setSearchFullscreen } from '../../state/searchSlice';

const HitCount = connectStateResults(({ searchResults, searches }) => {
	const hitCount = searchResults && searchResults.nbHits;
	return hitCount > 0 ? (
		<div className="HitCount">
			{searches}
			<div className="hitCount-number">{hitCount}</div>

			{/* {hitCount !== 1 ? `s` : ``} */}
		</div>
	) : null;
});

const PageHit = ({ hit }) => {
	const dispatch = useDispatch();
	const handleResult = () => {
		navigate(hit.path);
		dispatch(setSearchFullscreen(false));
	};

	const searchResult = hit?.context?.page?.content?.value?.document?.children || [];
	const searchResultChooseModule = hit?.context?.page?.chooseModule || [];

	// main items
	const isNestedBannerMainHeader = hit?.context?.page?.mainHeader || false;
	const isNestedBannerSubheader = hit?.context?.page?.subheader || false;
	const isNestedPageDescription = hit?.context?.page?.description || false;
	// choose module items - banners - always first
	const isTopBannerMainHeader = hit?.context?.page?.chooseModule?.[0]?.mainHeader || false;
	const isHomeBannerHeader = hit?.context?.page?.chooseModule?.[0]?.header || false;
	const isBannerSubheader = hit?.context?.page?.chooseModule?.[0]?.subheader || false;
	const isBannerDescription = hit?.context?.page?.chooseModule?.[0]?.description || false;
	// choose module items - random order
	// let isTabsHighlightedContentTitle = false;
	// let tabsTitleIndex = 0;
	// chooseModuleBase.forEach((item, key) => {
	// 	// console.log(item);
	// 	// console.log(key);
	// 	const temp = item?.tabsHighlightedContent || '';
	// 	if (temp.length > 0) {
	// 		isTabsHighlightedContentTitle = true;
	// 		tabsTitleIndex = key;
	// 	}
	// 	return '';
	// });

	return (
		<div>
			<div onClick={handleResult}>
				<h4>
					<Highlight attribute="context.page.singlePageName" hit={hit} tagName="mark" />
				</h4>
				{isNestedBannerMainHeader && (
					<>
						<Snippet attribute="context.page.mainHeader" hit={hit} tagName="mark" />
						<br />
					</>
				)}
				{isNestedBannerSubheader && (
					<>
						<Snippet attribute="context.page.subheader" hit={hit} tagName="mark" />
						<br />
					</>
				)}
				{isNestedPageDescription && (
					<>
						<Snippet attribute="context.page.description" hit={hit} tagName="mark" />
						<br />
					</>
				)}
				{isTopBannerMainHeader && (
					<>
						<Snippet attribute="context.page.chooseModule[0].mainHeader" hit={hit} tagName="mark" />
						<br />
					</>
				)}
				{isHomeBannerHeader && (
					<>
						<Snippet attribute="context.page.chooseModule[0].header" hit={hit} tagName="mark" />
						<br />
					</>
				)}
				{isBannerSubheader && (
					<>
						<Snippet attribute="context.page.chooseModule[0].subheader" hit={hit} tagName="mark" />
						<br />
					</>
				)}
				{isBannerDescription && (
					<>
						<Snippet attribute="context.page.chooseModule[0].description" hit={hit} tagName="mark" />
						<br />
					</>
				)}
				{searchResultChooseModule.length > 0 ? (
					<>
						{searchResultChooseModule.map((item, key) => {
							const innerContent = hit?.context?.page?.chooseModule[key].tabsHighlightedContent || [];
							return innerContent.map((innerItem, innerKey) => {
								return (
									<>
										{hit.context.page.chooseModule[key].tabsHighlightedContent[innerKey].title.length > 0 && (
											<Snippet
												attribute={`context.page.chooseModule[${key}].tabsHighlightedContent[${innerKey}].title`}
												hit={hit}
												tagName="mark"
											/>
										)}
										&nbsp;
										{hit.context.page.chooseModule[key].tabsHighlightedContent[innerKey].description.length > 0 && (
											<Snippet
												attribute={`context.page.chooseModule[${key}].tabsHighlightedContent[${innerKey}].description`}
												hit={hit}
												tagName="mark"
											/>
										)}
										&nbsp;
									</>
								);
							});
						})}
					</>
				) : (
					<></>
				)}
				{searchResult.length > 0 ? (
					<>
						{searchResult.map((item, key) => {
							const innerContent = hit.context.page.content.value.document.children[key].children || [];

							if (hit.context.page.content.value) {
								return innerContent.map((innerItem, innerKey) => {
									if (hit.context.page.content.value.document.children[key].children[innerKey].type === 'listItem') {
										const listContent =
											hit.context.page.content.value.document.children[key].children[innerKey].children || [];
										return listContent.map((listContentItem, listContentKey) => {
											return (
												<>
													<Snippet
														attribute={`context.page.content.value.document.children[${key}].children[${innerKey}].children[${listContentKey}].children[0].value`}
														hit={hit}
														tagName="mark"
													/>
													<Snippet
														attribute={`context.page.content.value.document.children[${key}].children[${innerKey}].children[${listContentKey}].children[0].children[0].children[0].value`}
														hit={hit}
														tagName="mark"
													/>
													&nbsp;
												</>
											);
										});
									}
									if (hit.context.page.content.value.document.children[key].children[innerKey].type === 'link') {
										return (
											<Snippet
												attribute={`context.page.content.value.document.children[${key}].children[${innerKey}].children[0].value`}
												hit={hit}
												tagName="mark"
											/>
										);
									}
									return (
										<>
											<Snippet
												attribute={`context.page.content.value.document.children[${key}].children[${innerKey}].value`}
												hit={hit}
												tagName="mark"
											/>
											&nbsp;
										</>
									);
								});
							}
						})}
					</>
				) : (
					<></>
				)}
				<br />
			</div>
		</div>
	);
};

const HitsInIndex = ({ index, searches }) => {
	return (
		<Index indexName={index.name}>
			<HitCount searches={searches} />
			<Hits className="Hits" hitComponent={PageHit} />
		</Index>
	);
};

const SearchResult = ({ indices, className, lang, searches }) => {
	return (
		<div className={className}>
			{/* <RefinementList attribute="lang" />; */}
			<Configure filters={`lang:${lang}`} distinct />
			{indices.map((index) => (
				<HitsInIndex index={index} key={index.name} searches={searches} />
			))}
			<PoweredBy />
		</div>
	);
};

export default connect((state) => ({
	lang: state.language.lang,
}))(SearchResult);
