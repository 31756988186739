import React from 'react';
import styled from 'styled-components';
import CustomCheckbox from '../../shared/CustomCheckbox';
import getHtmlFromRedactorField from '../../../utils/getHtmlFromRedactorField';

const StyledOptionWrapper = styled.div`
	&:nth-child(2n) {
		background-color: ${({ theme }) => theme.colors.grey};
	}
`;

const StyledOption = styled.div`
	//margin: 10px 0;
	align-items: center;
	display: flex;
	flex-direction: column;
	flex-flow: column-reverse;
	padding: 10px 25px;
	width: 100%;

	label {
		align-self: flex-end;
	}

	@media (min-width: 768px) {
		flex-direction: row;
		padding: 20px 40px;
	}
`;

const StyledTextWrapper = styled.div`
	width: 100%;

	span {
		//line-height: 2rem;
		font-size: 1rem;
		font-weight: ${({ theme }) => theme.fontWeight.bold};
	}

	@media (min-width: 768px) {
		width: calc(100% - 70px);
	}
`;

const StyledHeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const StyledLinkWrapper = styled.div`
	margin-top: 5px;
	a {
		color: ${({ theme }) => theme.colors.darkGreen};
		font-size: 0.8125rem;
		font-weight: ${({ theme }) => theme.fontWeight.bold};
		text-decoration: underline;
	}
`;

const StyledDescription = styled.div`
	font-size: 0.8125rem;
	line-height: 1.5rem;
`;
const StyledCheckboxWrapper = styled.div`
	display: none;
	&.mobile {
		display: flex;
	}

	@media (min-width: 768px) {
		margin-left: 10px;
		display: flex;
		&.mobile {
			display: none;
		}
	}
`;

const ExtendedOption = ({ checked, onChange, text, description, link, isDisabled }) => {
	return (
		<StyledOptionWrapper>
			<StyledOption>
				<StyledTextWrapper>
					<StyledHeaderWrapper>
						<span>{text}</span>
						<StyledCheckboxWrapper className="mobile">
							<CustomCheckbox checked={checked} onChange={onChange} isDisabled={isDisabled} colorSet="darkBlue" />
						</StyledCheckboxWrapper>
					</StyledHeaderWrapper>
					<StyledDescription dangerouslySetInnerHTML={getHtmlFromRedactorField(description)} />
					<StyledLinkWrapper dangerouslySetInnerHTML={getHtmlFromRedactorField(link)} />
				</StyledTextWrapper>
				<StyledCheckboxWrapper>
					<CustomCheckbox checked={checked} onChange={onChange} isDisabled={isDisabled} colorSet="darkBlue" />
				</StyledCheckboxWrapper>
			</StyledOption>
		</StyledOptionWrapper>
	);
};

export default ExtendedOption;
