import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from "js-cookie";
import { setSearchFullscreen } from '../../state/searchSlice';
import ModeSwitcher from '../common/menuAndFooter/ModeSwitcher';
import ContrastSwitcher from '../common/menuAndFooter/ContrastSwitcher';
import WcagFontSizeSwitcher from '../common/menuAndFooter/WcagFontSizeSwitcher';
import Bip from "../common/menuAndFooter/Bip";
import SingleSocialMediaItem from "../common/menuAndFooter/SingleSocialMediaItem";
import EuFlag from "../common/menuAndFooter/EuFlag";
import getPreparedSlug from "../../utils/getPreparedSlug";
import Magnifier from "../../assets/magnifier.svg";
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";
import LanguageSelectorNew from "../languageSelector/LanguageSelectorNew";

const StyledFooterMobileWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.green};
	display: none;
	flex-direction: column;
	justify-content: flex-start;
	padding: 70px 20px;
	position: relative;
	width: 100vw;
	@media (min-width: 300px) and (max-width: 1024px) {
		display: flex;
	}
`;

const StyledRowWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	max-width: 375px;
	width: 100%;
	&.space-between {
		justify-content: space-between;
		margin-bottom: 70px;
	}
	div {
		display: flex;
		flex-direction: row;
	}
`;

const StyledToolsWithHeader = styled.div`
	margin-bottom: 40px;
	text-align: left;
	width: 100%;
`

const StyledSubtitle = styled.div`
	color: ${({theme}) => theme.colors.darkBlue};
	font-size: 1rem;
	font-weight: ${({theme}) => theme.fontWeight.bold};
	line-height: 1.625rem;
	margin-bottom: 20px;
`

const StyledAddressInformationsWrapper = styled.div`
	border-top: 1px solid ${({theme}) => theme.colors.darkBlue};
	color: ${({theme}) => theme.colors.darkBlue};
	margin-top: 20px;
	padding-top: 20px;
	p:first-of-type {
		font-size: 1rem;
	}
	p {
		font-size: 0.875rem;
		line-height: 1.6875rem;
	}
`

const StyledSocialMediaWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	height: 100%;
	justify-content: flex-end;
`;

const StyledMagnifierContainer = styled.div`
	align-items: center;
	border-bottom: 1px solid ${({ theme }) => theme.colors.darkBlue};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 70px;
	padding: 0 16px 8px 20px;
	width: 100%;
	div {
		color: ${({theme}) => theme.colors.darkBlue};
		font-size: 1rem;
		font-weight: ${({ theme }) => theme.fontWeight.medium};
	}
`


const FooterMobileNew = ({ globals, activeSite, otherPagesPathsForLangChange, preparedSitemapSimplify }) => {
	const globalData = globals?.nodes?.[0] || [];
	const dispatch = useDispatch();
	const pageTheme = useSelector((state) => state.pageTheme.pageTheme);
	const searchFullscreen = useSelector((state) => state.searchFullscreen.searchFullscreen);
	const searchInPortText = globalData?.searchInPortText || '';
	const availabilityToolsText = globalData?.availabilityToolsText || '';
	const followUsText = globalData?.followUsText || '';
	const bipIconDark = globalData?.bipIconDark?.url || '';
	const bipHref = globalData?.bipUrl || '';
	const contrastOnIcon = globalData?.contrastOnIcon?.url || '';
	const contrastOffIcon = globalData?.contrastOffIcon?.url || '';
	const contrastDarkIcon = globalData?.contrastDarkIcon?.url || '';
	const wcagAIcon = globalData?.wcagAIcon?.url || '';
	const wcagAIconDarkMode = globalData?.wcagAIconDarkMode?.url || '';
	const wcagAPlusIcon = globalData?.wcagAPlusIcon?.url || '';
	const wcagAPlusIconDarkMode = globalData?.wcagAPlusIconDarkMode?.url || '';
	const ueFlagIcon = globalData?.ueFlagIcon?.url || '';
	const ueFlagUrlId = globalData?.ueFlagUrl?.id || '';
	const socialMediaData = globalData?.socialMedia || [];
	const isDarkMode = pageTheme === 'darkMode';
	const addressInformations = globalData?.footerAddressInformations || '';
	const preparedSlug = getPreparedSlug(ueFlagUrlId, preparedSitemapSimplify);
	const necessaryCookies = Cookies.get('necessaryCookies');

	const searchFullscreenHandler = () => {
		dispatch(setSearchFullscreen(!searchFullscreen));
	};
	
	return (
		<StyledFooterMobileWrapper>
			<StyledRowWrapper className='space-between'>
				<div>
					<ModeSwitcher isDarkBorder globals={globalData} />
					<LanguageSelectorNew isDarkSelector otherPagesPathsForLangChange={otherPagesPathsForLangChange} necessaryCookies={necessaryCookies} />
				</div>
				<div>
					<Bip bipHref={bipHref} bipIcon={bipIconDark}/>
					<EuFlag isFooter preparedSlug={preparedSlug} activeSite={activeSite}  ueFlagIcon={ueFlagIcon}/>
				</div>
			</StyledRowWrapper>
			<StyledRowWrapper>
				<StyledMagnifierContainer onClick={() => searchFullscreenHandler()}>
					<div>{searchInPortText}</div>
					<img alt="magnifier" src={Magnifier} />
				</StyledMagnifierContainer>
			</StyledRowWrapper>
			<StyledToolsWithHeader>
				<StyledSubtitle>{availabilityToolsText}</StyledSubtitle>
				<StyledRowWrapper>
					<WcagFontSizeSwitcher
						isDarkMode={isDarkMode}
						wcagAIcon={wcagAIcon}
						wcagAIconDarkMode={wcagAIconDarkMode}
						wcagAPlusIcon={wcagAPlusIcon}
						wcagAPlusIconDarkMode={wcagAPlusIconDarkMode}
						isFooter
					/>
					<ContrastSwitcher
						pageTheme={pageTheme}
						contrastOnIcon={contrastOnIcon}
						contrastOffIcon={contrastOffIcon}
						contrastDarkIcon={contrastDarkIcon}
						isFooter
					/>
				</StyledRowWrapper>
			</StyledToolsWithHeader>
			<StyledToolsWithHeader>
				<StyledSubtitle>{followUsText}</StyledSubtitle>
				<StyledRowWrapper>
					<StyledSocialMediaWrapper>
						{socialMediaData.map((item) => {
							const socialMediaUrl = item?.socialMediaLink || '';
							const socialMediaIcon = item?.icon?.url || '';
							const socialMediaDarkIcon = item?.iconDark?.url || '';
							return (
								<SingleSocialMediaItem
									key={socialMediaUrl}
									socialMediaUrl={socialMediaUrl}
									socialMediaIcon={socialMediaIcon}
									socialMediaDarkIcon={socialMediaDarkIcon}
									isFooter />
							);
						})}
					</StyledSocialMediaWrapper>
				</StyledRowWrapper>
			</StyledToolsWithHeader>
			<StyledAddressInformationsWrapper dangerouslySetInnerHTML={getHtmlFromRedactorField(addressInformations)}/>
		</StyledFooterMobileWrapper>
	);
};

export default FooterMobileNew;