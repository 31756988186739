import styled, { css } from 'styled-components';
import SearchResult from './search-result';

const Popover = css`
	-webkit-overflow-scrolling: touch;
	//box-shadow: 0 0 5px 0;
	//box-shadow: 0px 10px 11px 2px rgba(204, 236, 249, 0.65);
	//max-width: 30em;
	background: ${({ theme }) => theme.colors.white};
	border: none;
	border-radius: 2px;
	margin-top: 15px;
	max-height: 50vh;
	overflow-x: hidden;
	overflow-y: scroll;
	padding: 40px 110px 40px 50px;
	position: absolute;
	right: 0;
	top: 100%;
	width: 100%;
	z-index: 2;
	::-webkit-scrollbar {
		background: transparent;
		width: 0;
	}
	@media (max-width: 768px) {
		padding: 40px 30px 40px 30px;
	}
`;

export default styled(SearchResult)`
	display: ${(props) => (props.show ? `block` : `none`)};
	${Popover}

	.HitCount {
		display: flex;
		justify-content: center;
		float: right;
		border: 1px solid #000e41;
		width: 188px;
		font-size: 1.125rem;
		padding: 26px 25px 10px 12px;
		.hitCount-number {
			font-weight: bold;
			padding-left: 3px;
		}
	}
	mark {
		background-color: rgb(44, 254, 235) !important;
	}
	.Hits {
		ul {
			list-style: none;
			margin-left: 0;
		}

		li.ais-Hits-item {
			margin-bottom: 1em;

			a {
				color: ${({ theme }) => theme.colors.darkBlue};
				text-decoration: none;
				font-size: 1.25rem;

				h4 {
					margin-bottom: 0.2em;
				}
			}
		}
	}

	.ais-PoweredBy {
		display: none;
		font-size: 80%;
		justify-content: flex-end;

		svg {
			width: 70px;
		}
	}
`;
