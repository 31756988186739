// eslint-disable-next-line no-unused-vars

const setInitialColors = (theme) => {
	if (theme === 'darkMode') {
		document.documentElement.style.setProperty('--background-color', 'rgba(0, 14, 65, 1)');
		document.documentElement.style.setProperty('--background-color-dark', 'rgba(239, 239, 243, 1)');
		document.documentElement.style.setProperty('--background-grey', 'rgba(81, 81, 103, 1)');
		document.documentElement.style.setProperty('--background-grey-light', 'rgba(81, 81, 103, 1)');
		document.documentElement.style.setProperty('--text-color', 'rgba(245, 245, 245, 1)');
		document.documentElement.style.setProperty('--text-color-dark', 'rgba(0, 14, 65, 1)');
		document.documentElement.style.setProperty('--logo-color', 'rgb(255, 255, 255)');
		document.documentElement.style.setProperty('--filter', '1');
		document.documentElement.style.setProperty('--menu-color', 'rgb(0,14,65)');
		document.documentElement.style.setProperty('--middle-color', 'rgb(44, 254, 235)');
		document.documentElement.style.setProperty('--turquoise-color', 'rgba(0, 14, 65, 1)');
		document.documentElement.style.setProperty('--floral-color', 'rgba(0, 14, 65, 1)');
	} else if (theme === 'contrastMode') {
		document.documentElement.style.setProperty('--background-color', 'rgb(0, 0, 0)');
		document.documentElement.style.setProperty('--background-color-dark', 'rgb(0, 0, 0)');
		document.documentElement.style.setProperty('--background-grey', 'rgb(0, 0, 0)');
		document.documentElement.style.setProperty('--background-grey-light', 'rgb(0, 0, 0)');
		document.documentElement.style.setProperty('--text-color', 'rgb(255, 255, 255)');
		document.documentElement.style.setProperty('--text-color-dark', 'rgb(255, 255, 255)');
		document.documentElement.style.setProperty('--logo-color', 'rgb(255, 255, 255)');
		document.documentElement.style.setProperty('--filter', '1');
		document.documentElement.style.setProperty('--menu-color', 'rgb(0, 0, 0)');
		document.documentElement.style.setProperty('--middle-color', 'rgb(255, 255, 255)');
		document.documentElement.style.setProperty('--turquoise-color', 'rgb(0, 0, 0)');
		document.documentElement.style.setProperty('--floral-color', 'rgb(0, 0, 0)');
	} else {
		document.documentElement.style.setProperty('--background-color', 'rgb(255, 255, 255)');
		document.documentElement.style.setProperty('--background-color-dark', 'rgba(0, 14, 65, 1)');
		document.documentElement.style.setProperty('--background-grey', 'rgba(239, 239, 243, 1)');
		document.documentElement.style.setProperty('--background-grey-light', 'rgba(248, 246, 243, 1)');
		document.documentElement.style.setProperty('--text-color', 'rgba(0, 14, 65, 1)');
		document.documentElement.style.setProperty('--text-color-dark', 'rgba(255, 255, 255, 1)');
		document.documentElement.style.setProperty('--logo-color', 'rgb(0, 0, 0)');
		document.documentElement.style.setProperty('--filter', '0');
		document.documentElement.style.setProperty('--menu-color', 'rgb(0,14,65)');
		document.documentElement.style.setProperty('--middle-color', 'rgb(44, 254, 235)');
		document.documentElement.style.setProperty('--turquoise-color', 'rgba(143, 255, 247, 1)');
		document.documentElement.style.setProperty('--floral-color', 'rgba(248, 246, 243, 1)');
	}
};

export default setInitialColors;
