import { createSlice } from '@reduxjs/toolkit';

export const wcagFontSizePlusSlice = createSlice({
	name: 'wcagFontSizePlus',
	initialState: {
		wcagFontSizePlus: false,
	},
	reducers: {
		setWcagFontSizePlus: (state, action) => {
			state.wcagFontSizePlus = action.payload;
		},
	},
});

export const { setWcagFontSizePlus } = wcagFontSizePlusSlice.actions;

export default wcagFontSizePlusSlice.reducer;
