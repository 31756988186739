import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './counterSlice';
import pageThemeReducer from './pageThemeSlice';
import searchFullscreenReducer from './searchSlice';
import languageReducer from './languageSlice';
import menuFullscreenReducer from './menuFullscreenSlice';
import necessaryCookiesReducer from './necessaryCookiesSlice';
import wcagFontSizePlusReducer from './wcagFontSizePlusSlice';

export default configureStore({
	reducer: {
		counter: counterReducer,
		pageTheme: pageThemeReducer,
		searchFullscreen: searchFullscreenReducer,
		language: languageReducer,
		menuFullscreen: menuFullscreenReducer,
		necessaryCookies: necessaryCookiesReducer,
		wcagFontSizePlus: wcagFontSizePlusReducer,
	},
});
