import React from 'react';
import styled, { css } from 'styled-components';
import cn from 'classnames';
import { connect } from 'react-redux';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const media768 = ({ fontSize, lineHeight }) => css`
	@media (min-width: 768px) {
		font-size: ${fontSize || ''};
		line-height: ${lineHeight || ''};
	}
`;
const media1920 = ({ fontSize, lineHeight }) => css`
	@media (min-width: 1920px) {
		font-size: ${fontSize || ''};
		line-height: ${lineHeight || ''};
	}
`;

const StyledTypography = styled.div`
	color: var(--text-color);
	font-weight: ${({ theme }) => theme.fontWeight.regular};

	&.typography-variant {
		&-1 {
			font-size: 1.875rem;
			line-height: 1.875rem;
			font-family: ${({ theme }) => theme.fontFamily.secondary};
			${media768({ fontSize: '2.5rem', lineHeight: '3.438rem' })};
		}
		&-2 {
			font-family: ${({ theme }) => theme.fontFamily.primary};
			${media768({ fontSize: '1rem', lineHeight: '1.313rem' })};
		}
		&-3 {
			${media1920({ fontSize: '1.25rem', lineHeight: '2.625rem' })};
		}
		&-4 {
			${media1920({ fontSize: '1.75rem', lineHeight: '2.625rem' })};
		}
		&-5 {
			${media1920({ fontSize: '1rem', lineHeight: '2rem' })};
		}
		&-6 {
			font-size: 1.625rem;
			line-height: 2.3125rem;
			font-family: ${({ theme }) => theme.fontFamily.secondary};
			${media768({ fontSize: '3.76rem', lineHeight: '4.9375rem' })};
		}
		&-7 {
			font-size: 0.75rem;
			line-height: 1rem;
			letter-spacing: 0.04rem;
			${media768({ fontSize: '1rem', lineHeight: '1.1875rem' })};
		}
		&-8 {
			font-size: 2.5rem;
			line-height: 3rem;
			font-family: ${({ theme }) => theme.fontFamily.secondary};
			${media768({ fontSize: '3.75rem', lineHeight: '4.625rem' })};
		}
		&-9 {
			${media768({ fontSize: '1rem', lineHeight: '1.75rem' })};
			${media1920({ fontSize: '1.875rem', lineHeight: '2.375rem' })};
		}
		&-10 {
			${media768({ fontSize: '1rem', lineHeight: '1.5rem' })};
			${media1920({ fontSize: '1.875rem', lineHeight: '2rem' })};
		}
		&-11 {
			font-size: 0.6rem;
			line-height: 0.8rem;
			${media768({ fontSize: '0.75rem', lineHeight: '1rem' })};
		}
		&-12 {
			font-size: 2rem;
			line-height: 2.25rem;
			${media768({ fontSize: '3.125rem', lineHeight: '4rem' })};
		}
		&-13 {
			font-size: 1.438rem;
			line-height: 2rem;
		}
		&-14 {
			font-family: ${({ theme }) => theme.fontFamily.primary};
			font-size: 1.188rem;
			line-height: 1.75rem;
			${media768({ fontSize: '1.188rem', lineHeight: '2rem' })};
		}
		&-15 {
			font-size: 1.625rem;
			line-height: 2rem;
			font-family: ${({ theme }) => theme.fontFamily.secondary};
			${media768({ fontSize: '1.625rem', lineHeight: '2.5rem' })};
		}
	}

	&.color {
		&-black {
			color: ${({ theme }) => theme.colors.black};
		}
		&-white {
			color: ${({ theme }) => theme.colors.white};
		}
		&-green {
			color: ${({ theme }) => theme.colors.green};
		}
		&-dark-blue {
			color: ${({ theme }) => theme.colors.darkBlue};
		}
		&-light-grey {
			color: ${({ theme }) => theme.colors.lightGrey};
		}
	}

	&.font-weight {
		&-thin {
			font-weight: ${({ theme }) => theme.fontWeight.thin};
		}
		&-extra-light {
			font-weight: ${({ theme }) => theme.fontWeight.extraLight};
		}
		&-light {
			font-weight: ${({ theme }) => theme.fontWeight.light};
		}
		&-regular {
			font-weight: ${({ theme }) => theme.fontWeight.regular};
		}
		&-medium {
			font-weight: ${({ theme }) => theme.fontWeight.medium};
		}
		&-semi-bold {
			font-weight: ${({ theme }) => theme.fontWeight.semiBold};
		}
		&-bold {
			font-weight: ${({ theme }) => theme.fontWeight.bold};
		}
		&-extra-bold {
			font-weight: ${({ theme }) => theme.fontWeight.extraBold};
		}
		&-black {
			font-weight: ${({ theme }) => theme.fontWeight.black};
		}
	}

	&.dark-mode {
		color: rgb(255, 255, 255) !important;
	}
`;

const Typography = ({ variant, color, fontWeight, fontFamily, custom, pageTheme, children, ...props }) => {
	return (
		<StyledTypography
			className={cn({
				[`typography-variant-${variant}`]: variant,
				[`color-${color}`]: color,
				[`font-weight-${fontWeight}`]: fontWeight,
				[`font-family-${fontFamily}`]: fontFamily,
				[`custom-${custom}`]: custom,
				[`dark-mode`]: pageTheme === 'contrastMode',
			})}
			{...props}
			dangerouslySetInnerHTML={getHtmlFromRedactorField(children)}
		/>
	);
};

export default connect((state) => ({
	pageTheme: state.pageTheme.pageTheme,
}))(Typography);
