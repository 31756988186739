import { createGlobalStyle } from 'styled-components';

import { connect } from 'react-redux';

const GlobalStyle = createGlobalStyle`
	
  :root {
	--background-color: white;
	--text-color: rgba(0, 14, 65, 1);
	--background-grey: rgba(239, 239, 243, 1);
	--logo-color: black;
	--filter: 0;
	--font-size: 16px;
  }

  * {
	  scrollbar-width: none; 
	  -ms-overflow-style: none; 
  }

  *::-webkit-scrollbar {
	  display: none; 
  }
  
  // rems for 16px
  // 10px = 0.625rem
  // 11px = 0.6875rem
  // 12px = 0.75rem
  // 13px = 0.8125rem
  // 14px = 0.875rem
  // 15px = 0.9376rem
  // 16px = 1rem
  // 17px = 1.0625rem
  // 18px = 1.125rem
  // 19px = 1.1876rem
  // 20px = 1.25rem
  // 22px = 1.375rem
  // 23px = 1.4375rem
  // 24px = 1.5rem
  // 25px = 1.5625rem
  // 26px = 1.625rem
  // 30px = 1.875rem

  html {
	transition: background-color 0.3s;
	background-color: var(--background-color) !important;
    img {
      &.theme-change {
        
      }
    }
	padding-right:  ${(props) => (props.menuFullscreen ? '10px' : '0')};
	overflow-x: hidden;
	overflow-y: ${(props) => (props.menuFullscreen ? 'hidden' : 'auto')};
    font-size: var(--font-size);
	  height: var(--inner-height);
  }

  body {
	width: 100%;
	padding: 0;
	margin: 0;
	position: relative;
	overflow: hidden;
    background-color: ${(props) => (props.isErrorPage ? 'var(--text-color) !important' : 'var(--background-color) !important')};
	font-family: ${({ theme }) => theme.fontFamily.primary};
    color: var(--text-color);
    -webkit-tap-highlight-color: transparent !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	//box-sizing: border-box;
  }

  *, *::before, *::after {
	box-sizing: border-box;
  }

  textarea:focus, input:focus {
	outline: none;
  }

  h1, h2, h3, h4, h5, h6, p, ul {
	margin: 0;
	padding: 0;
  }

  ul {
	list-style-type: none;
  }
  
  a {
	color: ${({ theme }) => theme.colors.darkGreen};
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	text-decoration: none;
  }

  .dark-blue {
	color: ${({ theme }) => theme.colors.darkBlue}
  }

  button.control-arrow {
	opacity: 1 !important;
	background-color: ${({ theme }) => theme.colors.darkBlueAlpha} !important;
  }
  
  // contrast mode settings for all pages
  .contrast-mode {
	display:${({ pageTheme }) => (pageTheme === 'contrastMode' ? 'none' : '')} !important;
  }

  .contrast-mode-background {
	background: ${({ pageTheme }) => (pageTheme === 'contrastMode' ? 'rgb(0,0,0)' : '')} !important;
  }
  
  .news-animation-disabled {
	.slick-list {
	  .slick-track {
		transition: ${({ pageTheme }) => (pageTheme === 'contrastMode' ? 'none' : '')} !important;
	  }
	}
  }
  .simplebar-scrollbar:before {
	background-color:  ${({ theme }) => theme.colors.darkBlue} !important;
  }
  .simplebar-scrollbar.simplebar-visible:before {
  opacity: 1 !important;
  }
`;

export default connect((state) => ({
	pageTheme: state.pageTheme.pageTheme,
}))(GlobalStyle);
// export default GlobalStyle;
